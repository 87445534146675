import React from 'react'
import CarouselImage4 from "./carousel-image-4";
import {IProps} from "./carousel-item";
import CarouselImageMob4 from "./carousel-image-mob4";

const CarouselItem4 = ({...props}: IProps) => {
    return (
        <div className={`${props.className}`}>
            <div className="md:hidden"><CarouselImageMob4 /></div>
            <div className="hidden md:block"><CarouselImage4 /></div>
            <div className="mt-10 text-center">
                <p>That’s it! You can remove access at any time.</p>
            </div>
        </div>
    )
}

export default CarouselItem4