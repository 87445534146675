import React from 'react'
import {IProps} from "./arrow";

const LeftArrow = ({...props}: IProps) => {

    return (
        <svg className={props.className} onClick={props.onClick} width="30" height="30" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.387 15.238l6.56 7.817a1.163 1.163 0 101.781-1.495l-5.31-6.327 5.306-6.323a1.163 1.163 0 00-1.782-1.495l-6.559 7.818.004.005z"
                fill="#999"/>
        </svg>
    )

}

export default LeftArrow