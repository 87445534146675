import React from 'react'
import CarouselImage3 from "./carousel-image-3";
import {IProps} from "./carousel-item";
import CarouselImageMob3 from "./carousel-image-mob3";

const CarouselItem3 = ({...props}: IProps) => {
    return (
        <div className={`${props.className}`}>
            <div className="md:hidden"><CarouselImageMob3 /></div>
            <div className="hidden md:block"><CarouselImage3 /></div>
            <div className="mt-10 text-center">
                <p>Grant access to specific data.</p>
            </div>
        </div>
    )
}

export default CarouselItem3