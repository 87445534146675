import React from 'react'

export default function HomeLineHorizontal(){

    return (
        <svg width="392" height="2" viewBox="0 0 392 2" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="1.5" y1="1" x2="390.5" y2="1" stroke="#E4E4E4" strokeWidth="2" strokeLinecap="round"/>
        </svg>
    )

}