import React from 'react'
import {IProps} from "./arrow";

export default function RightArrow({...props}: IProps) {

    return (
        <svg className={props.className} onClick={props.onClick} width="30" height="30" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19.613 15.238l-6.56 7.817a1.163 1.163 0 11-1.781-1.495l5.31-6.327-5.306-6.323a1.163 1.163 0 011.782-1.495l6.559 7.818-.004.005z"
                fill="#999"/>
        </svg>
    )

}