import React from 'react'
import CarouselImage2 from "./carousel-image-2";
import {IProps} from "./carousel-item";
import CarouselImageMob2 from "./carousel-image-mob2";

const CarouselItem2 = ({...props}: IProps) => {
    return (
        <div className={`${props.className}`}>
            <div className="md:hidden"><CarouselImageMob2 /></div>
            <div className="hidden md:block"><CarouselImage2 /></div>
            <div className="mt-10 text-center">
                <p><a className="">Securely</a> log in to your
                    banking app or website.</p>
                <p className="text-sm mt-6">Remember: don't share your banking password with others.</p>
            </div>
        </div>
    )
}

export default CarouselItem2