import React, {useEffect, useState} from "react"
// import RightArrow from "../icons/right-arrow";
// import LeftArrow from "../icons/left-arrow";
import DotIndicator from "./dot-indicator";
import useInterval from "../../util/use-interval";
import RightArrow from "../icons/right-arrow";
import LeftArrow from "../icons/left-arrow";

interface IProps {
  components: Array<any> // todo fix
  autoplay?: boolean
  className?: string
  showDots?: boolean
  showArrows?: boolean
  loop?: boolean
}

const Carousel = ({...props}: IProps) => {

  const defaultDelay = 6000
  const [index, setIndex] = useState(0)
  const length = props.components.length - 1
  const [isPlaying, _] = useState(props.autoplay)
  const [delay, setDelay] = useState(defaultDelay)

  if (props.autoplay) {
    useInterval(
      () => handleNext(),
      // Delay in milliseconds or null to stop it
      isPlaying ? delay : null,
    )
  }

  const handleNext = () => {
    setDelay(defaultDelay)
    setIndex(index < length ? index + 1 : (props.loop ? 0 : index))
  }
  const handlePrevious = () => {
    setIndex(index > 0 ? index - 1 : (props.loop ? length : index))
  }

  const handleKeyPress = (event: any) => {
    if (event.keyCode === 37) handlePrevious()
    if (event.keyCode === 39) handleNext()
  }

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress)
    return () => {
      window.removeEventListener("keydown", handleKeyPress)
    }
  }, [handleKeyPress])

  const handleSelect = (selected: number) => {
    setDelay(defaultDelay)
    setIndex(selected)
  }

  const leftArrow =
    <LeftArrow className="cursor-pointer fill-white m-auto"
               onClick={() => handlePrevious()}/>
  const rightArrow =
    <RightArrow className="cursor-pointer fill-white m-auto"
                onClick={() => handleNext()}/>

  return (
    <>
      <div className={`relative py-20 ${props.className}`}
           onKeyDown={handleKeyPress}>
        <div
          className="w-full m-auto">
          <div className="">{props.components[index]}</div>
        </div>
        {props.showArrows ? <div className="flex flex-wrap">
          <div
            className="m-auto inline-block w-1/12">{index > 0 ? leftArrow : <></>}
          </div>
          <div
            className="m-auto inline-block w-1/12">{index < length ? rightArrow : <></>}</div>
        </div> : <></>}
        {props.showDots ? <div>
          <div className="mb-4 flex flex-wrap">
            <DotIndicator className="m-auto" n={4} selected={index}
                          onSelect={handleSelect}/>
          </div>
        </div> : <></>}
      </div>
    </>

  )

}

export default Carousel