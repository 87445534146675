import React, {useEffect, useRef, useState} from 'react'
// import UseHover from "../../util/use-hover";

export default function PhoneInCircle(){

    const useHover = () => {
        const [value, setValue] = useState(false);
        const ref = useRef(null);
        const handleMouseOver = () => setValue(true);
        const handleMouseOut = () => setValue(false);
        useEffect(
            () => {
                const node = ref.current;
                if (node) {
                    node.addEventListener("mouseover", handleMouseOver);
                    node.addEventListener("mouseout", handleMouseOut);
                    return () => {
                        node.removeEventListener("mouseover", handleMouseOver);
                        node.removeEventListener("mouseout", handleMouseOut);
                    };
                }
            },
            // [ref.current]
        );
        return [ref, value];
    }
    const [ref, isHovered] = useHover()

    return (

        <svg ref={ref} width="136" height="218" viewBox="0 0 136 218" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="68" cy="150.5" r="67" strokeWidth="2" fill={isHovered ? "#DEAD00" : "#9991"} stroke="#E4E4E4"/>
            <mask id="mask0" mask="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="135" height="218">
                <path d="M135 0.5H0V150.5C0 187.779 30.2227 218 67.5 218C104.777 218 135 187.779 135 150.5V0.5Z" fill="#FFF"/>
            </mask>
            <g mask="url(#mask0)">
                <g filter="url(#filter0_d)" transform={isHovered ? "translate(0, -10) scale(1.05)" : ""} style={{ transformOrigin: 'center', transition: "all .3s ease-out"}}>
                    <rect x="27.5" y="56" width="82" height="177.557" rx="10.7119" fill="white"/>
                    <rect x="28.7969" y="57.2984" width="79.2029" height="174.311" rx="9.41346" fill="#E6E6E6"/>
                    <rect x="31.7188" y="59.8952" width="73.3601" height="169.118" rx="9.32543" fill={isHovered ? "#FFF" : "#FDFDFD"} style={{ transformOrigin: 'center', transition: "all .3s ease"}}/>
                </g>
            </g>
            <g><path d="M110.299 202.459C98.4665 212.092 83.7002 217.398 68.4425 217.499C53.1848 217.599 38.3497 212.489 26.3907 203.014" strokeWidth="2" stroke="#E4E4E4"/></g>
            <defs>
                <filter id="filter0_d" x="24.254" y="52.754" width="88.492" height="184.049" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                    <feOffset/>
                    <feGaussianBlur stdDeviation="1.62301"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                </filter>
            </defs>
        </svg>

    )

}