import React from 'react'

interface IProps {
    className?: string
}

const Check = ({...props}: IProps) => {

    return (
        <svg className={props.className} width="13" height="10" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.83 8.59l.01-.01 6.211-6.212A1.34 1.34 0 0010.156.473l-6.21 6.211-1.659-1.658A1.34 1.34 0 00.392 6.921l1.659 1.658.01.011.937.937a1.34 1.34 0 001.895 0l.936-.937z"
                fill="#72C256"/>
        </svg>
    )

}

export default Check