import React from 'react'

export default function HomeLineVertical() {

    return (
        <svg width="13" height="44" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <path d="M6.5 43.831V.169" stroke="#E4E4E4" strokeWidth="2"
                      strokeLinecap="round"/>
            </g>
            <defs>
                <clipPath id="clip0">
                    <path fill="#fff" transform="rotate(-90 22 22)"
                          d="M0 0h44v13H0z"/>
                </clipPath>
            </defs>
        </svg>
    )

}