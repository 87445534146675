import React from 'react'

interface IProps {
    n: number;
    selected?: number;
    className?: string;
    onSelect: (selected: number) => void;
}

const DotIndicator = ({...props}: IProps) => {

    return <div className='m-auto'>
        {Array.from(new Array(props.n), (_, n_i) => {
            return <svg key={`dot_${n_i}`} onClick={() => props.onSelect(n_i)} width='20' height='20' className={`cursor-pointer inline-block dot-indicator ${n_i === props.selected ? "selected" : ""} ${props.className}`}>
                <circle style={{transition: 'all .5 ease'}} r="5" cx='10' cy="10"/>
            </svg>
        })
        }
    </div>

}

export default DotIndicator