import React from "react"

export function TickBullet() {
    return (
        <svg width="13" height="10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.33 8.59a2.95 2.95 0 00.01-.01l6.211-6.212A1.34 1.34 0 0010.656.473l-6.21 6.211-1.659-1.658A1.34 1.34 0 10.892 6.921L2.551 8.58l.01.011.937.937a1.34 1.34 0 001.895 0l.936-.937z"
                  // fill="#121212"
                  className="fill-primary"
            />
        </svg>
    )
}