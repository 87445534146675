import React from 'react'

export default function Finances() {

    return (

        <svg width="124" height="217" viewBox="0 0 124 217" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="124" height="217"/>
            <g id="Frame">
                <rect width="1440" height="819" transform="translate(-330 -553)"/>
                <g id="body">
                    <rect width="1440" height="4626" transform="translate(-330 -553)"/>
                    <g id="coin stacks">
                        <g id="coin stack" className="coin-bar">
                            <g id="coin">
                                <ellipse id="Ellipse 2" cx="13.6719" cy="208.602" rx="13.6719" ry="4.88281" fill="#eee"/>
                                <path id="Exclude" d="M0 208.602V212.117C0 214.814 6.12111 217 13.6719 217C21.2226 217 27.3438 214.814 27.3438 212.117V208.643L27.3433 208.643C27.2802 211.321 21.1835 213.484 13.6719 213.484C6.12111 213.484 0 211.298 0 208.602Z" fill="#eee"/>
                                <path id="Ellipse 1" d="M22.8516 208.211C22.8516 208.589 22.6358 208.976 22.1848 209.354C21.7348 209.73 21.072 210.079 20.2358 210.375C18.5652 210.966 16.2445 211.336 13.6719 211.336C11.0992 211.336 8.77854 210.966 7.10795 210.375C6.27179 210.079 5.60899 209.73 5.159 209.354C4.70797 208.976 4.49219 208.589 4.49219 208.211C4.49219 207.833 4.70797 207.446 5.159 207.068C5.60899 206.691 6.27179 206.343 7.10795 206.047C8.77854 205.456 11.0992 205.086 13.6719 205.086C16.2445 205.086 18.5652 205.456 20.2358 206.047C21.072 206.343 21.7348 206.691 22.1848 207.068C22.6358 207.446 22.8516 207.833 22.8516 208.211Z" fill="#CDCBBF" stroke="#999999" strokeWidth="0.390625"/>
                                <ellipse id="Ellipse 3" cx="13.6719" cy="208.602" rx="9.375" ry="3.32031" fill="#fff"/>
                                <g id="Subtract"  opacity="0.4">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M27.333 208.797C27.3401 208.862 27.3438 208.927 27.3438 208.992C27.3438 211.689 21.2226 213.875 13.6719 213.875C6.12111 213.875 0 211.689 0 208.992C0 208.927 0.00360152 208.862 0.0107376 208.797C0.297794 211.403 6.30426 213.484 13.6719 213.484C21.0395 213.484 27.046 211.403 27.333 208.797Z" fill="#ABABAB"/>
                                </g>
                            </g>
                            <g className="coin-left" id="coin with bottom">
                                <path id="bottom" d="M0 205.086V208.602C0 211.298 6.12111 213.484 13.6719 213.484C21.2226 213.484 27.3438 211.298 27.3438 208.602V205.128L27.3433 205.128C27.2802 207.805 21.1835 209.969 13.6719 209.969C6.12111 209.969 0 207.783 0 205.086Z" fill="#999999"/>
                                <g>
                                    <ellipse id="Ellipse 2_2" cx="13.6719" cy="204.695" rx="13.6719" ry="4.88281" fill="#eee"/>
                                    <path id="Exclude_2" d="M0 204.695V208.211C0 210.908 6.12111 213.094 13.6719 213.094C21.2226 213.094 27.3438 210.908 27.3438 208.211V204.737L27.3433 204.737C27.2802 207.415 21.1835 209.578 13.6719 209.578C6.12111 209.578 0 207.392 0 204.695Z" fill="#eee"/>
                                    <path id="Ellipse 1_2" d="M22.8516 204.305C22.8516 204.683 22.6358 205.07 22.1848 205.447C21.7348 205.824 21.072 206.172 20.2358 206.468C18.5652 207.06 16.2445 207.43 13.6719 207.43C11.0992 207.43 8.77854 207.06 7.10795 206.468C6.27179 206.172 5.60899 205.824 5.159 205.447C4.70797 205.07 4.49219 204.683 4.49219 204.305C4.49219 203.927 4.70797 203.54 5.159 203.162C5.60899 202.785 6.27179 202.437 7.10795 202.141C8.77854 201.549 11.0992 201.18 13.6719 201.18C16.2445 201.18 18.5652 201.549 20.2358 202.141C21.072 202.437 21.7348 202.785 22.1848 203.162C22.6358 203.54 22.8516 203.927 22.8516 204.305Z" fill="#CDCBBF" stroke="#999999" strokeWidth="0.390625"/>
                                    <ellipse id="Ellipse 3_2" cx="13.6719" cy="204.695" rx="9.375" ry="3.32031" fill="#fff"/>
                                    <g id="Subtract_2"  opacity="0.4">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M27.333 204.891C27.3401 204.955 27.3438 205.021 27.3438 205.086C27.3438 207.783 21.2226 209.969 13.6719 209.969C6.12111 209.969 0 207.783 0 205.086C0 205.021 0.00360152 204.955 0.0107376 204.891C0.297794 207.497 6.30426 209.578 13.6719 209.578C21.0395 209.578 27.046 207.497 27.333 204.891Z" fill="#ABABAB"/>
                                    </g>
                                </g>
                            </g>
                            <g className="coin-left" id="coin with bottom_2">
                                <path id="bottom_2" d="M0 201.18V204.695C0 207.392 6.12111 209.578 13.6719 209.578C21.2226 209.578 27.3438 207.392 27.3438 204.695V201.222L27.3433 201.222C27.2802 203.899 21.1835 206.062 13.6719 206.062C6.12111 206.062 0 203.876 0 201.18Z" fill="#999999"/>
                                <g>
                                    <ellipse id="Ellipse 2_3" cx="13.6719" cy="200.789" rx="13.6719" ry="4.88281" fill="#eee"/>
                                    <path id="Exclude_3" d="M0 200.789V204.305C0 207.001 6.12111 209.188 13.6719 209.188C21.2226 209.188 27.3438 207.001 27.3438 204.305V200.831L27.3433 200.831C27.2802 203.508 21.1835 205.672 13.6719 205.672C6.12111 205.672 0 203.486 0 200.789Z" fill="#eee"/>
                                    <path id="Ellipse 1_3" d="M22.8516 200.398C22.8516 200.776 22.6358 201.163 22.1848 201.541C21.7348 201.918 21.072 202.266 20.2358 202.562C18.5652 203.154 16.2445 203.523 13.6719 203.523C11.0992 203.523 8.77854 203.154 7.10795 202.562C6.27179 202.266 5.60899 201.918 5.159 201.541C4.70797 201.163 4.49219 200.776 4.49219 200.398C4.49219 200.02 4.70797 199.633 5.159 199.256C5.60899 198.879 6.27179 198.531 7.10795 198.235C8.77854 197.643 11.0992 197.273 13.6719 197.273C16.2445 197.273 18.5652 197.643 20.2358 198.235C21.072 198.531 21.7348 198.879 22.1848 199.256C22.6358 199.633 22.8516 200.02 22.8516 200.398Z" fill="#CDCBBF" stroke="#999999" strokeWidth="0.390625"/>
                                    <ellipse id="Ellipse 3_3" cx="13.6719" cy="200.789" rx="9.375" ry="3.32031" fill="#fff"/>
                                    <g id="Subtract_3"  opacity="0.4">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M27.333 200.984C27.3401 201.049 27.3438 201.114 27.3438 201.18C27.3438 203.876 21.2226 206.062 13.6719 206.062C6.12111 206.062 0 203.876 0 201.18C0 201.114 0.00360152 201.049 0.0107376 200.984C0.297794 203.591 6.30426 205.672 13.6719 205.672C21.0395 205.672 27.046 203.591 27.333 200.984Z" fill="#ABABAB"/>
                                    </g>
                                </g>
                            </g>
                            <g className="coin-left" id="coin with bottom_3">
                                <path id="bottom_3" d="M0 197.273V200.789C0 203.486 6.12111 205.672 13.6719 205.672C21.2226 205.672 27.3438 203.486 27.3438 200.789V197.315L27.3433 197.315C27.2802 199.993 21.1835 202.156 13.6719 202.156C6.12111 202.156 0 199.97 0 197.273Z" fill="#999999"/>
                                <g>
                                    <ellipse id="Ellipse 2_4" cx="13.6719" cy="196.883" rx="13.6719" ry="4.88281" fill="#eee"/>
                                    <path id="Exclude_4" d="M0 196.883V200.398C0 203.095 6.12111 205.281 13.6719 205.281C21.2226 205.281 27.3438 203.095 27.3438 200.398V196.925L27.3433 196.925C27.2802 199.602 21.1835 201.766 13.6719 201.766C6.12111 201.766 0 199.58 0 196.883Z" fill="#eee"/>
                                    <path id="Ellipse 1_4" d="M22.8516 196.492C22.8516 196.87 22.6358 197.257 22.1848 197.635C21.7348 198.012 21.072 198.36 20.2358 198.656C18.5652 199.248 16.2445 199.617 13.6719 199.617C11.0992 199.617 8.77854 199.248 7.10795 198.656C6.27179 198.36 5.60899 198.012 5.159 197.635C4.70797 197.257 4.49219 196.87 4.49219 196.492C4.49219 196.114 4.70797 195.727 5.159 195.35C5.60899 194.973 6.27179 194.625 7.10795 194.328C8.77854 193.737 11.0992 193.367 13.6719 193.367C16.2445 193.367 18.5652 193.737 20.2358 194.328C21.072 194.625 21.7348 194.973 22.1848 195.35C22.6358 195.727 22.8516 196.114 22.8516 196.492Z" fill="#CDCBBF" stroke="#999999" strokeWidth="0.390625"/>
                                    <ellipse id="Ellipse 3_4" cx="13.6719" cy="196.883" rx="9.375" ry="3.32031" fill="#fff"/>
                                    <g id="Subtract_4"  opacity="0.4">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M27.333 197.078C27.3401 197.143 27.3438 197.208 27.3438 197.273C27.3438 199.97 21.2226 202.156 13.6719 202.156C6.12111 202.156 0 199.97 0 197.273C0 197.208 0.00360152 197.143 0.0107376 197.078C0.297794 199.684 6.30426 201.766 13.6719 201.766C21.0395 201.766 27.046 199.684 27.333 197.078Z" fill="#ABABAB"/>
                                    </g>
                                </g>
                            </g>
                            <g className="coin-left" id="coin with bottom_4">
                                <path id="bottom_4" d="M0 193.273V196.789C0 199.486 6.12111 201.672 13.6719 201.672C21.2226 201.672 27.3438 199.486 27.3438 196.789V193.315L27.3433 193.315C27.2802 195.993 21.1835 198.156 13.6719 198.156C6.12111 198.156 0 195.97 0 193.273Z" fill="#999999"/>
                                <g>
                                    <ellipse id="Ellipse 2_5" cx="13.6719" cy="192.883" rx="13.6719" ry="4.88281" fill="#eee"/>
                                    <path id="Exclude_5" d="M0 192.883V196.398C0 199.095 6.12111 201.281 13.6719 201.281C21.2226 201.281 27.3438 199.095 27.3438 196.398V192.925L27.3433 192.925C27.2802 195.602 21.1835 197.766 13.6719 197.766C6.12111 197.766 0 195.58 0 192.883Z" fill="#eee"/>
                                    <path id="Ellipse 1_5" d="M22.8516 192.492C22.8516 192.87 22.6358 193.257 22.1848 193.635C21.7348 194.012 21.072 194.36 20.2358 194.656C18.5652 195.248 16.2445 195.617 13.6719 195.617C11.0992 195.617 8.77854 195.248 7.10795 194.656C6.27179 194.36 5.60899 194.012 5.159 193.635C4.70797 193.257 4.49219 192.87 4.49219 192.492C4.49219 192.114 4.70797 191.727 5.159 191.35C5.60899 190.973 6.27179 190.625 7.10795 190.328C8.77854 189.737 11.0992 189.367 13.6719 189.367C16.2445 189.367 18.5652 189.737 20.2358 190.328C21.072 190.625 21.7348 190.973 22.1848 191.35C22.6358 191.727 22.8516 192.114 22.8516 192.492Z" fill="#CDCBBF" stroke="#999999" strokeWidth="0.390625"/>
                                    <ellipse id="Ellipse 3_5" cx="13.6719" cy="192.883" rx="9.375" ry="3.32031" fill="#fff"/>
                                    <g id="Subtract_5"  opacity="0.4">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M27.333 193.078C27.3401 193.143 27.3438 193.208 27.3438 193.273C27.3438 195.97 21.2226 198.156 13.6719 198.156C6.12111 198.156 0 195.97 0 193.273C0 193.208 0.00360152 193.143 0.0107376 193.078C0.297794 195.684 6.30426 197.766 13.6719 197.766C21.0395 197.766 27.046 195.684 27.333 193.078Z" fill="#ABABAB"/>
                                    </g>
                                </g>
                            </g>
                            <g className="coin-left" id="coin with bottom_5">
                                <path id="bottom_5" d="M0 189.273V192.789C0 195.486 6.12111 197.672 13.6719 197.672C21.2226 197.672 27.3438 195.486 27.3438 192.789V189.315L27.3433 189.315C27.2802 191.993 21.1835 194.156 13.6719 194.156C6.12111 194.156 0 191.97 0 189.273Z" fill="#999999"/>
                                <g>
                                    <ellipse id="Ellipse 2_6" cx="13.6719" cy="188.883" rx="13.6719" ry="4.88281" fill="#eee"/>
                                    <path id="Exclude_6" d="M0 188.883V192.398C0 195.095 6.12111 197.281 13.6719 197.281C21.2226 197.281 27.3438 195.095 27.3438 192.398V188.925L27.3433 188.925C27.2802 191.602 21.1835 193.766 13.6719 193.766C6.12111 193.766 0 191.58 0 188.883Z" fill="#eee"/>
                                    <path id="Ellipse 1_6" d="M22.8516 188.492C22.8516 188.87 22.6358 189.257 22.1848 189.635C21.7348 190.012 21.072 190.36 20.2358 190.656C18.5652 191.248 16.2445 191.617 13.6719 191.617C11.0992 191.617 8.77854 191.248 7.10795 190.656C6.27179 190.36 5.60899 190.012 5.159 189.635C4.70797 189.257 4.49219 188.87 4.49219 188.492C4.49219 188.114 4.70797 187.727 5.159 187.35C5.60899 186.973 6.27179 186.625 7.10795 186.328C8.77854 185.737 11.0992 185.367 13.6719 185.367C16.2445 185.367 18.5652 185.737 20.2358 186.328C21.072 186.625 21.7348 186.973 22.1848 187.35C22.6358 187.727 22.8516 188.114 22.8516 188.492Z" fill="#CDCBBF" stroke="#999999" strokeWidth="0.390625"/>
                                    <ellipse id="Ellipse 3_6" cx="13.6719" cy="188.883" rx="9.375" ry="3.32031" fill="#fff"/>
                                    <g id="Subtract_6"  opacity="0.4">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M27.333 189.078C27.3401 189.143 27.3438 189.208 27.3438 189.273C27.3438 191.97 21.2226 194.156 13.6719 194.156C6.12111 194.156 0 191.97 0 189.273C0 189.208 0.00360152 189.143 0.0107376 189.078C0.297794 191.684 6.30426 193.766 13.6719 193.766C21.0395 193.766 27.046 191.684 27.333 189.078Z" fill="#ABABAB"/>
                                    </g>
                                </g>
                            </g>
                            <g className="coin-left" id="coin with bottom_6">
                                <path id="bottom_6" d="M0 185.273V188.789C0 191.486 6.12111 193.672 13.6719 193.672C21.2226 193.672 27.3438 191.486 27.3438 188.789V185.315L27.3433 185.315C27.2802 187.993 21.1835 190.156 13.6719 190.156C6.12111 190.156 0 187.97 0 185.273Z" fill="#999999"/>
                                <g>
                                    <ellipse id="Ellipse 2_7" cx="13.6719" cy="184.883" rx="13.6719" ry="4.88281" fill="#eee"/>
                                    <path id="Exclude_7" d="M0 184.883V188.398C0 191.095 6.12111 193.281 13.6719 193.281C21.2226 193.281 27.3438 191.095 27.3438 188.398V184.925L27.3433 184.925C27.2802 187.602 21.1835 189.766 13.6719 189.766C6.12111 189.766 0 187.58 0 184.883Z" fill="#eee"/>
                                    <path id="Ellipse 1_7" d="M22.8516 184.492C22.8516 184.87 22.6358 185.257 22.1848 185.635C21.7348 186.012 21.072 186.36 20.2358 186.656C18.5652 187.248 16.2445 187.617 13.6719 187.617C11.0992 187.617 8.77854 187.248 7.10795 186.656C6.27179 186.36 5.60899 186.012 5.159 185.635C4.70797 185.257 4.49219 184.87 4.49219 184.492C4.49219 184.114 4.70797 183.727 5.159 183.35C5.60899 182.973 6.27179 182.625 7.10795 182.328C8.77854 181.737 11.0992 181.367 13.6719 181.367C16.2445 181.367 18.5652 181.737 20.2358 182.328C21.072 182.625 21.7348 182.973 22.1848 183.35C22.6358 183.727 22.8516 184.114 22.8516 184.492Z" fill="#CDCBBF" stroke="#999999" strokeWidth="0.390625"/>
                                    <ellipse id="Ellipse 3_7" cx="13.6719" cy="184.883" rx="9.375" ry="3.32031" fill="#fff"/>
                                    <g id="Subtract_7"  opacity="0.4">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M27.333 185.078C27.3401 185.143 27.3438 185.208 27.3438 185.273C27.3438 187.97 21.2226 190.156 13.6719 190.156C6.12111 190.156 0 187.97 0 185.273C0 185.208 0.00360152 185.143 0.0107376 185.078C0.297794 187.684 6.30426 189.766 13.6719 189.766C21.0395 189.766 27.046 187.684 27.333 185.078Z" fill="#ABABAB"/>
                                    </g>
                                </g>
                            </g>
                            <g className="coin-left" id="coin with bottom_7">
                                <path id="bottom_7" d="M0 181.273V184.789C0 187.486 6.12111 189.672 13.6719 189.672C21.2226 189.672 27.3438 187.486 27.3438 184.789V181.315L27.3433 181.315C27.2802 183.993 21.1835 186.156 13.6719 186.156C6.12111 186.156 0 183.97 0 181.273Z" fill="#999999"/>
                                <g>
                                    <ellipse id="Ellipse 2_8" cx="13.6719" cy="180.883" rx="13.6719" ry="4.88281" fill="#eee"/>
                                    <path id="Exclude_8" d="M0 180.883V184.398C0 187.095 6.12111 189.281 13.6719 189.281C21.2226 189.281 27.3438 187.095 27.3438 184.398V180.925L27.3433 180.925C27.2802 183.602 21.1835 185.766 13.6719 185.766C6.12111 185.766 0 183.58 0 180.883Z" fill="#eee"/>
                                    <path id="Ellipse 1_8" d="M22.8516 180.492C22.8516 180.87 22.6358 181.257 22.1848 181.635C21.7348 182.012 21.072 182.36 20.2358 182.656C18.5652 183.248 16.2445 183.617 13.6719 183.617C11.0992 183.617 8.77854 183.248 7.10795 182.656C6.27179 182.36 5.60899 182.012 5.159 181.635C4.70797 181.257 4.49219 180.87 4.49219 180.492C4.49219 180.114 4.70797 179.727 5.159 179.35C5.60899 178.973 6.27179 178.625 7.10795 178.328C8.77854 177.737 11.0992 177.367 13.6719 177.367C16.2445 177.367 18.5652 177.737 20.2358 178.328C21.072 178.625 21.7348 178.973 22.1848 179.35C22.6358 179.727 22.8516 180.114 22.8516 180.492Z" fill="#CDCBBF" stroke="#999999" strokeWidth="0.390625"/>
                                    <ellipse id="Ellipse 3_8" cx="13.6719" cy="180.883" rx="9.375" ry="3.32031" fill="#fff"/>
                                    <g id="Subtract_8"  opacity="0.4">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M27.333 181.078C27.3401 181.143 27.3438 181.208 27.3438 181.273C27.3438 183.97 21.2226 186.156 13.6719 186.156C6.12111 186.156 0 183.97 0 181.273C0 181.208 0.00360152 181.143 0.0107376 181.078C0.297794 183.684 6.30426 185.766 13.6719 185.766C21.0395 185.766 27.046 183.684 27.333 181.078Z" fill="#ABABAB"/>
                                    </g>
                                </g>
                            </g>
                            <g className="coin-left" id="coin with bottom_8">
                                <path id="bottom_8" d="M0 177.273V180.789C0 183.486 6.12111 185.672 13.6719 185.672C21.2226 185.672 27.3438 183.486 27.3438 180.789V177.315L27.3433 177.315C27.2802 179.993 21.1835 182.156 13.6719 182.156C6.12111 182.156 0 179.97 0 177.273Z" fill="#999999"/>
                                <g>
                                    <ellipse id="Ellipse 2_9" cx="13.6719" cy="176.883" rx="13.6719" ry="4.88281" fill="#eee"/>
                                    <path id="Exclude_9" d="M0 176.883V180.398C0 183.095 6.12111 185.281 13.6719 185.281C21.2226 185.281 27.3438 183.095 27.3438 180.398V176.925L27.3433 176.925C27.2802 179.602 21.1835 181.766 13.6719 181.766C6.12111 181.766 0 179.58 0 176.883Z" fill="#eee"/>
                                    <path id="Ellipse 1_9" d="M22.8516 176.492C22.8516 176.87 22.6358 177.257 22.1848 177.635C21.7348 178.012 21.072 178.36 20.2358 178.656C18.5652 179.248 16.2445 179.617 13.6719 179.617C11.0992 179.617 8.77854 179.248 7.10795 178.656C6.27179 178.36 5.60899 178.012 5.159 177.635C4.70797 177.257 4.49219 176.87 4.49219 176.492C4.49219 176.114 4.70797 175.727 5.159 175.35C5.60899 174.973 6.27179 174.625 7.10795 174.328C8.77854 173.737 11.0992 173.367 13.6719 173.367C16.2445 173.367 18.5652 173.737 20.2358 174.328C21.072 174.625 21.7348 174.973 22.1848 175.35C22.6358 175.727 22.8516 176.114 22.8516 176.492Z" fill="#CDCBBF" stroke="#999999" strokeWidth="0.390625"/>
                                    <ellipse id="Ellipse 3_9" cx="13.6719" cy="176.883" rx="9.375" ry="3.32031" fill="#fff"/>
                                    <g id="Subtract_9"  opacity="0.4">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M27.333 177.078C27.3401 177.143 27.3438 177.208 27.3438 177.273C27.3438 179.97 21.2226 182.156 13.6719 182.156C6.12111 182.156 0 179.97 0 177.273C0 177.208 0.00360152 177.143 0.0107376 177.078C0.297794 179.684 6.30426 181.766 13.6719 181.766C21.0395 181.766 27.046 179.684 27.333 177.078Z" fill="#ABABAB"/>
                                    </g>
                                </g>
                            </g>
                            <g className="coin-left" id="coin with bottom_9">
                                <path id="bottom_9" d="M0 173.273V176.789C0 179.486 6.12111 181.672 13.6719 181.672C21.2226 181.672 27.3438 179.486 27.3438 176.789V173.315L27.3433 173.315C27.2802 175.993 21.1835 178.156 13.6719 178.156C6.12111 178.156 0 175.97 0 173.273Z" fill="#999999"/>
                                <g>
                                    <ellipse id="Ellipse 2_10" cx="13.6719" cy="172.883" rx="13.6719" ry="4.88281" fill="#eee"/>
                                    <path id="Exclude_10" d="M0 172.883V176.398C0 179.095 6.12111 181.281 13.6719 181.281C21.2226 181.281 27.3438 179.095 27.3438 176.398V172.925L27.3433 172.925C27.2802 175.602 21.1835 177.766 13.6719 177.766C6.12111 177.766 0 175.58 0 172.883Z" fill="#eee"/>
                                    <path id="Ellipse 1_10" d="M22.8516 172.492C22.8516 172.87 22.6358 173.257 22.1848 173.635C21.7348 174.012 21.072 174.36 20.2358 174.656C18.5652 175.248 16.2445 175.617 13.6719 175.617C11.0992 175.617 8.77854 175.248 7.10795 174.656C6.27179 174.36 5.60899 174.012 5.159 173.635C4.70797 173.257 4.49219 172.87 4.49219 172.492C4.49219 172.114 4.70797 171.727 5.159 171.35C5.60899 170.973 6.27179 170.625 7.10795 170.328C8.77854 169.737 11.0992 169.367 13.6719 169.367C16.2445 169.367 18.5652 169.737 20.2358 170.328C21.072 170.625 21.7348 170.973 22.1848 171.35C22.6358 171.727 22.8516 172.114 22.8516 172.492Z" fill="#CDCBBF" stroke="#999999" strokeWidth="0.390625"/>
                                    <ellipse id="Ellipse 3_10" cx="13.6719" cy="172.883" rx="9.375" ry="3.32031" fill="#fff"/>
                                    <g id="Subtract_10"  opacity="0.4">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M27.333 173.078C27.3401 173.143 27.3438 173.208 27.3438 173.273C27.3438 175.97 21.2226 178.156 13.6719 178.156C6.12111 178.156 0 175.97 0 173.273C0 173.208 0.00360152 173.143 0.0107376 173.078C0.297794 175.684 6.30426 177.766 13.6719 177.766C21.0395 177.766 27.046 175.684 27.333 173.078Z" fill="#ABABAB"/>
                                    </g>
                                </g>
                            </g>
                            <g className="coin-left" id="coin with bottom_10">
                                <path id="bottom_10" d="M0 169.273V172.789C0 175.486 6.12111 177.672 13.6719 177.672C21.2226 177.672 27.3438 175.486 27.3438 172.789V169.315L27.3433 169.315C27.2802 171.993 21.1835 174.156 13.6719 174.156C6.12111 174.156 0 171.97 0 169.273Z" fill="#999999"/>
                                <g>
                                    <ellipse id="Ellipse 2_11" cx="13.6719" cy="168.883" rx="13.6719" ry="4.88281" fill="#eee"/>
                                    <path id="Exclude_11" d="M0 168.883V172.398C0 175.095 6.12111 177.281 13.6719 177.281C21.2226 177.281 27.3438 175.095 27.3438 172.398V168.925L27.3433 168.925C27.2802 171.602 21.1835 173.766 13.6719 173.766C6.12111 173.766 0 171.58 0 168.883Z" fill="#eee"/>
                                    <path id="Ellipse 1_11" d="M22.8516 168.492C22.8516 168.87 22.6358 169.257 22.1848 169.635C21.7348 170.012 21.072 170.36 20.2358 170.656C18.5652 171.248 16.2445 171.617 13.6719 171.617C11.0992 171.617 8.77854 171.248 7.10795 170.656C6.27179 170.36 5.60899 170.012 5.159 169.635C4.70797 169.257 4.49219 168.87 4.49219 168.492C4.49219 168.114 4.70797 167.727 5.159 167.35C5.60899 166.973 6.27179 166.625 7.10795 166.328C8.77854 165.737 11.0992 165.367 13.6719 165.367C16.2445 165.367 18.5652 165.737 20.2358 166.328C21.072 166.625 21.7348 166.973 22.1848 167.35C22.6358 167.727 22.8516 168.114 22.8516 168.492Z" fill="#CDCBBF" stroke="#999999" strokeWidth="0.390625"/>
                                    <ellipse id="Ellipse 3_11" cx="13.6719" cy="168.883" rx="9.375" ry="3.32031" fill="#fff"/>
                                    <g id="Subtract_11"  opacity="0.4">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M27.333 169.078C27.3401 169.143 27.3438 169.208 27.3438 169.273C27.3438 171.97 21.2226 174.156 13.6719 174.156C6.12111 174.156 0 171.97 0 169.273C0 169.208 0.00360152 169.143 0.0107376 169.078C0.297794 171.684 6.30426 173.766 13.6719 173.766C21.0395 173.766 27.046 171.684 27.333 169.078Z" fill="#ABABAB"/>
                                    </g>
                                </g>
                            </g>
                            <g className="coin-left" id="coin with bottom_11">
                                <path id="bottom_11" d="M0 165.273V168.789C0 171.486 6.12111 173.672 13.6719 173.672C21.2226 173.672 27.3438 171.486 27.3438 168.789V165.315L27.3433 165.315C27.2802 167.993 21.1835 170.156 13.6719 170.156C6.12111 170.156 0 167.97 0 165.273Z" fill="#999999"/>
                                <g>
                                    <ellipse id="Ellipse 2_12" cx="13.6719" cy="164.883" rx="13.6719" ry="4.88281" fill="#eee"/>
                                    <path id="Exclude_12" d="M0 164.883V168.398C0 171.095 6.12111 173.281 13.6719 173.281C21.2226 173.281 27.3438 171.095 27.3438 168.398V164.925L27.3433 164.925C27.2802 167.602 21.1835 169.766 13.6719 169.766C6.12111 169.766 0 167.58 0 164.883Z" fill="#eee"/>
                                    <path id="Ellipse 1_12" d="M22.8516 164.492C22.8516 164.87 22.6358 165.257 22.1848 165.635C21.7348 166.012 21.072 166.36 20.2358 166.656C18.5652 167.248 16.2445 167.617 13.6719 167.617C11.0992 167.617 8.77854 167.248 7.10795 166.656C6.27179 166.36 5.60899 166.012 5.159 165.635C4.70797 165.257 4.49219 164.87 4.49219 164.492C4.49219 164.114 4.70797 163.727 5.159 163.35C5.60899 162.973 6.27179 162.625 7.10795 162.328C8.77854 161.737 11.0992 161.367 13.6719 161.367C16.2445 161.367 18.5652 161.737 20.2358 162.328C21.072 162.625 21.7348 162.973 22.1848 163.35C22.6358 163.727 22.8516 164.114 22.8516 164.492Z" fill="#CDCBBF" stroke="#999999" strokeWidth="0.390625"/>
                                    <ellipse id="Ellipse 3_12" cx="13.6719" cy="164.883" rx="9.375" ry="3.32031" fill="#fff"/>
                                    <g id="Subtract_12"  opacity="0.4">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M27.333 165.078C27.3401 165.143 27.3438 165.208 27.3438 165.273C27.3438 167.97 21.2226 170.156 13.6719 170.156C6.12111 170.156 0 167.97 0 165.273C0 165.208 0.00360152 165.143 0.0107376 165.078C0.297794 167.684 6.30426 169.766 13.6719 169.766C21.0395 169.766 27.046 167.684 27.333 165.078Z" fill="#ABABAB"/>
                                    </g>
                                </g>
                            </g>
                            <g className="coin-left" id="coin with bottom_12">
                                <path id="bottom_12" d="M0 161.273V164.789C0 167.486 6.12111 169.672 13.6719 169.672C21.2226 169.672 27.3438 167.486 27.3438 164.789V161.315L27.3433 161.315C27.2802 163.993 21.1835 166.156 13.6719 166.156C6.12111 166.156 0 163.97 0 161.273Z" fill="#999999"/>
                                <g>
                                    <ellipse id="Ellipse 2_13" cx="13.6719" cy="160.883" rx="13.6719" ry="4.88281" fill="#eee"/>
                                    <path id="Exclude_13" d="M0 160.883V164.398C0 167.095 6.12111 169.281 13.6719 169.281C21.2226 169.281 27.3438 167.095 27.3438 164.398V160.925L27.3433 160.925C27.2802 163.602 21.1835 165.766 13.6719 165.766C6.12111 165.766 0 163.58 0 160.883Z" fill="#eee"/>
                                    <path id="Ellipse 1_13" d="M22.8516 160.492C22.8516 160.87 22.6358 161.257 22.1848 161.635C21.7348 162.012 21.072 162.36 20.2358 162.656C18.5652 163.248 16.2445 163.617 13.6719 163.617C11.0992 163.617 8.77854 163.248 7.10795 162.656C6.27179 162.36 5.60899 162.012 5.159 161.635C4.70797 161.257 4.49219 160.87 4.49219 160.492C4.49219 160.114 4.70797 159.727 5.159 159.35C5.60899 158.973 6.27179 158.625 7.10795 158.328C8.77854 157.737 11.0992 157.367 13.6719 157.367C16.2445 157.367 18.5652 157.737 20.2358 158.328C21.072 158.625 21.7348 158.973 22.1848 159.35C22.6358 159.727 22.8516 160.114 22.8516 160.492Z" fill="#CDCBBF" stroke="#999999" strokeWidth="0.390625"/>
                                    <ellipse id="Ellipse 3_13" cx="13.6719" cy="160.883" rx="9.375" ry="3.32031" fill="#fff"/>
                                    <g id="Subtract_13"  opacity="0.4">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M27.333 161.078C27.3401 161.143 27.3438 161.208 27.3438 161.273C27.3438 163.97 21.2226 166.156 13.6719 166.156C6.12111 166.156 0 163.97 0 161.273C0 161.208 0.00360152 161.143 0.0107376 161.078C0.297794 163.684 6.30426 165.766 13.6719 165.766C21.0395 165.766 27.046 163.684 27.333 161.078Z" fill="#ABABAB"/>
                                    </g>
                                </g>
                            </g>
                            <g className="coin-left" id="coin with bottom_13">
                                <path id="bottom_13" d="M0 157.273V160.789C0 163.486 6.12111 165.672 13.6719 165.672C21.2226 165.672 27.3438 163.486 27.3438 160.789V157.315L27.3433 157.315C27.2802 159.993 21.1835 162.156 13.6719 162.156C6.12111 162.156 0 159.97 0 157.273Z" fill="#999999"/>
                                <g>
                                    <ellipse id="Ellipse 2_14" cx="13.6719" cy="156.883" rx="13.6719" ry="4.88281" fill="#eee"/>
                                    <path id="Exclude_14" d="M0 156.883V160.398C0 163.095 6.12111 165.281 13.6719 165.281C21.2226 165.281 27.3438 163.095 27.3438 160.398V156.925L27.3433 156.925C27.2802 159.602 21.1835 161.766 13.6719 161.766C6.12111 161.766 0 159.58 0 156.883Z" fill="#eee"/>
                                    <path id="Ellipse 1_14" d="M22.8516 156.492C22.8516 156.87 22.6358 157.257 22.1848 157.635C21.7348 158.012 21.072 158.36 20.2358 158.656C18.5652 159.248 16.2445 159.617 13.6719 159.617C11.0992 159.617 8.77854 159.248 7.10795 158.656C6.27179 158.36 5.60899 158.012 5.159 157.635C4.70797 157.257 4.49219 156.87 4.49219 156.492C4.49219 156.114 4.70797 155.727 5.159 155.35C5.60899 154.973 6.27179 154.625 7.10795 154.328C8.77854 153.737 11.0992 153.367 13.6719 153.367C16.2445 153.367 18.5652 153.737 20.2358 154.328C21.072 154.625 21.7348 154.973 22.1848 155.35C22.6358 155.727 22.8516 156.114 22.8516 156.492Z" fill="#CDCBBF" stroke="#999999" strokeWidth="0.390625"/>
                                    <ellipse id="Ellipse 3_14" cx="13.6719" cy="156.883" rx="9.375" ry="3.32031" fill="#fff"/>
                                    <g id="Subtract_14"  opacity="0.4">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M27.333 157.078C27.3401 157.143 27.3438 157.208 27.3438 157.273C27.3438 159.97 21.2226 162.156 13.6719 162.156C6.12111 162.156 0 159.97 0 157.273C0 157.208 0.00360152 157.143 0.0107376 157.078C0.297794 159.684 6.30426 161.766 13.6719 161.766C21.0395 161.766 27.046 159.684 27.333 157.078Z" fill="#ABABAB"/>
                                    </g>
                                </g>
                            </g>
                            <g className="coin-left" id="coin with bottom_14">
                                <path id="bottom_14" d="M0 153.273V156.789C0 159.486 6.12111 161.672 13.6719 161.672C21.2226 161.672 27.3438 159.486 27.3438 156.789V153.315L27.3433 153.315C27.2802 155.993 21.1835 158.156 13.6719 158.156C6.12111 158.156 0 155.97 0 153.273Z" fill="#999999"/>
                                <g>
                                    <ellipse id="Ellipse 2_15" cx="13.6719" cy="152.883" rx="13.6719" ry="4.88281" fill="#eee"/>
                                    <path id="Exclude_15" d="M0 152.883V156.398C0 159.095 6.12111 161.281 13.6719 161.281C21.2226 161.281 27.3438 159.095 27.3438 156.398V152.925L27.3433 152.925C27.2802 155.602 21.1835 157.766 13.6719 157.766C6.12111 157.766 0 155.58 0 152.883Z" fill="#eee"/>
                                    <path id="Ellipse 1_15" d="M22.8516 152.492C22.8516 152.87 22.6358 153.257 22.1848 153.635C21.7348 154.012 21.072 154.36 20.2358 154.656C18.5652 155.248 16.2445 155.617 13.6719 155.617C11.0992 155.617 8.77854 155.248 7.10795 154.656C6.27179 154.36 5.60899 154.012 5.159 153.635C4.70797 153.257 4.49219 152.87 4.49219 152.492C4.49219 152.114 4.70797 151.727 5.159 151.35C5.60899 150.973 6.27179 150.625 7.10795 150.328C8.77854 149.737 11.0992 149.367 13.6719 149.367C16.2445 149.367 18.5652 149.737 20.2358 150.328C21.072 150.625 21.7348 150.973 22.1848 151.35C22.6358 151.727 22.8516 152.114 22.8516 152.492Z" fill="#CDCBBF" stroke="#999999" strokeWidth="0.390625"/>
                                    <ellipse id="Ellipse 3_15" cx="13.6719" cy="152.883" rx="9.375" ry="3.32031" fill="#fff"/>
                                    <g id="Subtract_15"  opacity="0.4">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M27.333 153.078C27.3401 153.143 27.3438 153.208 27.3438 153.273C27.3438 155.97 21.2226 158.156 13.6719 158.156C6.12111 158.156 0 155.97 0 153.273C0 153.208 0.00360152 153.143 0.0107376 153.078C0.297794 155.684 6.30426 157.766 13.6719 157.766C21.0395 157.766 27.046 155.684 27.333 153.078Z" fill="#ABABAB"/>
                                    </g>
                                </g>
                            </g>
                        </g>
                        <g id="coin stack_2" className="coin-bar">
                            <g>
                                <ellipse id="Ellipse 2_16" cx="45.6719" cy="208.602" rx="13.6719" ry="4.88281" fill="#eee"/>
                                <path id="Exclude_16" d="M32 208.602V212.117C32 214.814 38.1211 217 45.6719 217C53.2226 217 59.3438 214.814 59.3438 212.117V208.643L59.3433 208.643C59.2802 211.321 53.1835 213.484 45.6719 213.484C38.1211 213.484 32 211.298 32 208.602Z" fill="#eee"/>
                                <path id="Ellipse 1_16" d="M54.8516 208.211C54.8516 208.589 54.6358 208.976 54.1848 209.354C53.7348 209.73 53.072 210.079 52.2358 210.375C50.5652 210.966 48.2445 211.336 45.6719 211.336C43.0992 211.336 40.7785 210.966 39.108 210.375C38.2718 210.079 37.609 209.73 37.159 209.354C36.708 208.976 36.4922 208.589 36.4922 208.211C36.4922 207.833 36.708 207.446 37.159 207.068C37.609 206.691 38.2718 206.343 39.108 206.047C40.7785 205.456 43.0992 205.086 45.6719 205.086C48.2445 205.086 50.5652 205.456 52.2358 206.047C53.072 206.343 53.7348 206.691 54.1848 207.068C54.6358 207.446 54.8516 207.833 54.8516 208.211Z" fill="#CDCBBF" stroke="#999999" strokeWidth="0.390625"/>
                                <ellipse id="Ellipse 3_16" cx="45.6719" cy="208.602" rx="9.375" ry="3.32031" fill="#fff"/>
                                <g id="Subtract_16"  opacity="0.4">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M59.333 208.797C59.3401 208.862 59.3438 208.927 59.3438 208.992C59.3438 211.689 53.2226 213.875 45.6719 213.875C38.1211 213.875 32 211.689 32 208.992C32 208.927 32.0036 208.862 32.0107 208.797C32.2978 211.403 38.3043 213.484 45.6719 213.484C53.0395 213.484 59.046 211.403 59.333 208.797Z" fill="#ABABAB"/>
                                </g>
                            </g>
                            <g className="coin-left" id="coin with bottom_15">
                                <path id="bottom_15" d="M32 205.086V208.602C32 211.298 38.1211 213.484 45.6719 213.484C53.2226 213.484 59.3438 211.298 59.3438 208.602V205.128L59.3433 205.128C59.2802 207.805 53.1835 209.969 45.6719 209.969C38.1211 209.969 32 207.783 32 205.086Z" fill="#999999"/>
                                <g>
                                    <ellipse id="Ellipse 2_17" cx="45.6719" cy="204.695" rx="13.6719" ry="4.88281" fill="#eee"/>
                                    <path id="Exclude_17" d="M32 204.695V208.211C32 210.908 38.1211 213.094 45.6719 213.094C53.2226 213.094 59.3438 210.908 59.3438 208.211V204.737L59.3433 204.737C59.2802 207.415 53.1835 209.578 45.6719 209.578C38.1211 209.578 32 207.392 32 204.695Z" fill="#eee"/>
                                    <path id="Ellipse 1_17" d="M54.8516 204.305C54.8516 204.683 54.6358 205.07 54.1848 205.447C53.7348 205.824 53.072 206.172 52.2358 206.468C50.5652 207.06 48.2445 207.43 45.6719 207.43C43.0992 207.43 40.7785 207.06 39.108 206.468C38.2718 206.172 37.609 205.824 37.159 205.447C36.708 205.07 36.4922 204.683 36.4922 204.305C36.4922 203.927 36.708 203.54 37.159 203.162C37.609 202.785 38.2718 202.437 39.108 202.141C40.7785 201.549 43.0992 201.18 45.6719 201.18C48.2445 201.18 50.5652 201.549 52.2358 202.141C53.072 202.437 53.7348 202.785 54.1848 203.162C54.6358 203.54 54.8516 203.927 54.8516 204.305Z" fill="#CDCBBF" stroke="#999999" strokeWidth="0.390625"/>
                                    <ellipse id="Ellipse 3_17" cx="45.6719" cy="204.695" rx="9.375" ry="3.32031" fill="#fff"/>
                                    <g id="Subtract_17"  opacity="0.4">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M59.333 204.891C59.3401 204.955 59.3438 205.021 59.3438 205.086C59.3438 207.783 53.2226 209.969 45.6719 209.969C38.1211 209.969 32 207.783 32 205.086C32 205.021 32.0036 204.955 32.0107 204.891C32.2978 207.497 38.3043 209.578 45.6719 209.578C53.0395 209.578 59.046 207.497 59.333 204.891Z" fill="#ABABAB"/>
                                    </g>
                                </g>
                            </g>
                            <g className="coin-left" id="coin with bottom_16">
                                <path id="bottom_16" d="M32 201.18V204.695C32 207.392 38.1211 209.578 45.6719 209.578C53.2226 209.578 59.3438 207.392 59.3438 204.695V201.222L59.3433 201.222C59.2802 203.899 53.1835 206.062 45.6719 206.062C38.1211 206.062 32 203.876 32 201.18Z" fill="#999999"/>
                                <g>
                                    <ellipse id="Ellipse 2_18" cx="45.6719" cy="200.789" rx="13.6719" ry="4.88281" fill="#eee"/>
                                    <path id="Exclude_18" d="M32 200.789V204.305C32 207.001 38.1211 209.188 45.6719 209.188C53.2226 209.188 59.3438 207.001 59.3438 204.305V200.831L59.3433 200.831C59.2802 203.508 53.1835 205.672 45.6719 205.672C38.1211 205.672 32 203.486 32 200.789Z" fill="#eee"/>
                                    <path id="Ellipse 1_18" d="M54.8516 200.398C54.8516 200.776 54.6358 201.163 54.1848 201.541C53.7348 201.918 53.072 202.266 52.2358 202.562C50.5652 203.154 48.2445 203.523 45.6719 203.523C43.0992 203.523 40.7785 203.154 39.108 202.562C38.2718 202.266 37.609 201.918 37.159 201.541C36.708 201.163 36.4922 200.776 36.4922 200.398C36.4922 200.02 36.708 199.633 37.159 199.256C37.609 198.879 38.2718 198.531 39.108 198.235C40.7785 197.643 43.0992 197.273 45.6719 197.273C48.2445 197.273 50.5652 197.643 52.2358 198.235C53.072 198.531 53.7348 198.879 54.1848 199.256C54.6358 199.633 54.8516 200.02 54.8516 200.398Z" fill="#CDCBBF" stroke="#999999" strokeWidth="0.390625"/>
                                    <ellipse id="Ellipse 3_18" cx="45.6719" cy="200.789" rx="9.375" ry="3.32031" fill="#fff"/>
                                    <g id="Subtract_18"  opacity="0.4">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M59.333 200.984C59.3401 201.049 59.3438 201.114 59.3438 201.18C59.3438 203.876 53.2226 206.062 45.6719 206.062C38.1211 206.062 32 203.876 32 201.18C32 201.114 32.0036 201.049 32.0107 200.984C32.2978 203.591 38.3043 205.672 45.6719 205.672C53.0395 205.672 59.046 203.591 59.333 200.984Z" fill="#ABABAB"/>
                                    </g>
                                </g>
                            </g>
                            <g className="coin-left" id="coin with bottom_17">
                                <path id="bottom_17" d="M32 197.273V200.789C32 203.486 38.1211 205.672 45.6719 205.672C53.2226 205.672 59.3438 203.486 59.3438 200.789V197.315L59.3433 197.315C59.2802 199.993 53.1835 202.156 45.6719 202.156C38.1211 202.156 32 199.97 32 197.273Z" fill="#999999"/>
                                <g>
                                    <ellipse id="Ellipse 2_19" cx="45.6719" cy="196.883" rx="13.6719" ry="4.88281" fill="#eee"/>
                                    <path id="Exclude_19" d="M32 196.883V200.398C32 203.095 38.1211 205.281 45.6719 205.281C53.2226 205.281 59.3438 203.095 59.3438 200.398V196.925L59.3433 196.925C59.2802 199.602 53.1835 201.766 45.6719 201.766C38.1211 201.766 32 199.58 32 196.883Z" fill="#eee"/>
                                    <path id="Ellipse 1_19" d="M54.8516 196.492C54.8516 196.87 54.6358 197.257 54.1848 197.635C53.7348 198.012 53.072 198.36 52.2358 198.656C50.5652 199.248 48.2445 199.617 45.6719 199.617C43.0992 199.617 40.7785 199.248 39.108 198.656C38.2718 198.36 37.609 198.012 37.159 197.635C36.708 197.257 36.4922 196.87 36.4922 196.492C36.4922 196.114 36.708 195.727 37.159 195.35C37.609 194.973 38.2718 194.625 39.108 194.328C40.7785 193.737 43.0992 193.367 45.6719 193.367C48.2445 193.367 50.5652 193.737 52.2358 194.328C53.072 194.625 53.7348 194.973 54.1848 195.35C54.6358 195.727 54.8516 196.114 54.8516 196.492Z" fill="#CDCBBF" stroke="#999999" strokeWidth="0.390625"/>
                                    <ellipse id="Ellipse 3_19" cx="45.6719" cy="196.883" rx="9.375" ry="3.32031" fill="#fff"/>
                                    <g id="Subtract_19"  opacity="0.4">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M59.333 197.078C59.3401 197.143 59.3438 197.208 59.3438 197.273C59.3438 199.97 53.2226 202.156 45.6719 202.156C38.1211 202.156 32 199.97 32 197.273C32 197.208 32.0036 197.143 32.0107 197.078C32.2978 199.684 38.3043 201.766 45.6719 201.766C53.0395 201.766 59.046 199.684 59.333 197.078Z" fill="#ABABAB"/>
                                    </g>
                                </g>
                            </g>
                            <g className="coin-left" id="coin with bottom_18">
                                <path id="bottom_18" d="M32 193.273V196.789C32 199.486 38.1211 201.672 45.6719 201.672C53.2226 201.672 59.3438 199.486 59.3438 196.789V193.315L59.3433 193.315C59.2802 195.993 53.1835 198.156 45.6719 198.156C38.1211 198.156 32 195.97 32 193.273Z" fill="#999999"/>
                                <g>
                                    <ellipse id="Ellipse 2_20" cx="45.6719" cy="192.883" rx="13.6719" ry="4.88281" fill="#eee"/>
                                    <path id="Exclude_20" d="M32 192.883V196.398C32 199.095 38.1211 201.281 45.6719 201.281C53.2226 201.281 59.3438 199.095 59.3438 196.398V192.925L59.3433 192.925C59.2802 195.602 53.1835 197.766 45.6719 197.766C38.1211 197.766 32 195.58 32 192.883Z" fill="#eee"/>
                                    <path id="Ellipse 1_20" d="M54.8516 192.492C54.8516 192.87 54.6358 193.257 54.1848 193.635C53.7348 194.012 53.072 194.36 52.2358 194.656C50.5652 195.248 48.2445 195.617 45.6719 195.617C43.0992 195.617 40.7785 195.248 39.108 194.656C38.2718 194.36 37.609 194.012 37.159 193.635C36.708 193.257 36.4922 192.87 36.4922 192.492C36.4922 192.114 36.708 191.727 37.159 191.35C37.609 190.973 38.2718 190.625 39.108 190.328C40.7785 189.737 43.0992 189.367 45.6719 189.367C48.2445 189.367 50.5652 189.737 52.2358 190.328C53.072 190.625 53.7348 190.973 54.1848 191.35C54.6358 191.727 54.8516 192.114 54.8516 192.492Z" fill="#CDCBBF" stroke="#999999" strokeWidth="0.390625"/>
                                    <ellipse id="Ellipse 3_20" cx="45.6719" cy="192.883" rx="9.375" ry="3.32031" fill="#fff"/>
                                    <g id="Subtract_20"  opacity="0.4">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M59.333 193.078C59.3401 193.143 59.3438 193.208 59.3438 193.273C59.3438 195.97 53.2226 198.156 45.6719 198.156C38.1211 198.156 32 195.97 32 193.273C32 193.208 32.0036 193.143 32.0107 193.078C32.2978 195.684 38.3043 197.766 45.6719 197.766C53.0395 197.766 59.046 195.684 59.333 193.078Z" fill="#ABABAB"/>
                                    </g>
                                </g>
                            </g>
                            <g className="coin-left" id="coin with bottom_19">
                                <path id="bottom_19" d="M32 189.273V192.789C32 195.486 38.1211 197.672 45.6719 197.672C53.2226 197.672 59.3438 195.486 59.3438 192.789V189.315L59.3433 189.315C59.2802 191.993 53.1835 194.156 45.6719 194.156C38.1211 194.156 32 191.97 32 189.273Z" fill="#999999"/>
                                <g>
                                    <ellipse id="Ellipse 2_21" cx="45.6719" cy="188.883" rx="13.6719" ry="4.88281" fill="#eee"/>
                                    <path id="Exclude_21" d="M32 188.883V192.398C32 195.095 38.1211 197.281 45.6719 197.281C53.2226 197.281 59.3438 195.095 59.3438 192.398V188.925L59.3433 188.925C59.2802 191.602 53.1835 193.766 45.6719 193.766C38.1211 193.766 32 191.58 32 188.883Z" fill="#eee"/>
                                    <path id="Ellipse 1_21" d="M54.8516 188.492C54.8516 188.87 54.6358 189.257 54.1848 189.635C53.7348 190.012 53.072 190.36 52.2358 190.656C50.5652 191.248 48.2445 191.617 45.6719 191.617C43.0992 191.617 40.7785 191.248 39.108 190.656C38.2718 190.36 37.609 190.012 37.159 189.635C36.708 189.257 36.4922 188.87 36.4922 188.492C36.4922 188.114 36.708 187.727 37.159 187.35C37.609 186.973 38.2718 186.625 39.108 186.328C40.7785 185.737 43.0992 185.367 45.6719 185.367C48.2445 185.367 50.5652 185.737 52.2358 186.328C53.072 186.625 53.7348 186.973 54.1848 187.35C54.6358 187.727 54.8516 188.114 54.8516 188.492Z" fill="#CDCBBF" stroke="#999999" strokeWidth="0.390625"/>
                                    <ellipse id="Ellipse 3_21" cx="45.6719" cy="188.883" rx="9.375" ry="3.32031" fill="#fff"/>
                                    <g id="Subtract_21"  opacity="0.4">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M59.333 189.078C59.3401 189.143 59.3438 189.208 59.3438 189.273C59.3438 191.97 53.2226 194.156 45.6719 194.156C38.1211 194.156 32 191.97 32 189.273C32 189.208 32.0036 189.143 32.0107 189.078C32.2978 191.684 38.3043 193.766 45.6719 193.766C53.0395 193.766 59.046 191.684 59.333 189.078Z" fill="#ABABAB"/>
                                    </g>
                                </g>
                            </g>
                            <g className="coin-left" id="coin with bottom_20">
                                <path id="bottom_20" d="M32 185.273V188.789C32 191.486 38.1211 193.672 45.6719 193.672C53.2226 193.672 59.3438 191.486 59.3438 188.789V185.315L59.3433 185.315C59.2802 187.993 53.1835 190.156 45.6719 190.156C38.1211 190.156 32 187.97 32 185.273Z" fill="#999999"/>
                                <g>
                                    <ellipse id="Ellipse 2_22" cx="45.6719" cy="184.883" rx="13.6719" ry="4.88281" fill="#eee"/>
                                    <path id="Exclude_22" d="M32 184.883V188.398C32 191.095 38.1211 193.281 45.6719 193.281C53.2226 193.281 59.3438 191.095 59.3438 188.398V184.925L59.3433 184.925C59.2802 187.602 53.1835 189.766 45.6719 189.766C38.1211 189.766 32 187.58 32 184.883Z" fill="#eee"/>
                                    <path id="Ellipse 1_22" d="M54.8516 184.492C54.8516 184.87 54.6358 185.257 54.1848 185.635C53.7348 186.012 53.072 186.36 52.2358 186.656C50.5652 187.248 48.2445 187.617 45.6719 187.617C43.0992 187.617 40.7785 187.248 39.108 186.656C38.2718 186.36 37.609 186.012 37.159 185.635C36.708 185.257 36.4922 184.87 36.4922 184.492C36.4922 184.114 36.708 183.727 37.159 183.35C37.609 182.973 38.2718 182.625 39.108 182.328C40.7785 181.737 43.0992 181.367 45.6719 181.367C48.2445 181.367 50.5652 181.737 52.2358 182.328C53.072 182.625 53.7348 182.973 54.1848 183.35C54.6358 183.727 54.8516 184.114 54.8516 184.492Z" fill="#CDCBBF" stroke="#999999" strokeWidth="0.390625"/>
                                    <ellipse id="Ellipse 3_22" cx="45.6719" cy="184.883" rx="9.375" ry="3.32031" fill="#fff"/>
                                    <g id="Subtract_22"  opacity="0.4">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M59.333 185.078C59.3401 185.143 59.3438 185.208 59.3438 185.273C59.3438 187.97 53.2226 190.156 45.6719 190.156C38.1211 190.156 32 187.97 32 185.273C32 185.208 32.0036 185.143 32.0107 185.078C32.2978 187.684 38.3043 189.766 45.6719 189.766C53.0395 189.766 59.046 187.684 59.333 185.078Z" fill="#ABABAB"/>
                                    </g>
                                </g>
                            </g>
                            <g className="coin-left" id="coin with bottom_21">
                                <path id="bottom_21" d="M32 181.273V184.789C32 187.486 38.1211 189.672 45.6719 189.672C53.2226 189.672 59.3438 187.486 59.3438 184.789V181.315L59.3433 181.315C59.2802 183.993 53.1835 186.156 45.6719 186.156C38.1211 186.156 32 183.97 32 181.273Z" fill="#999999"/>
                                <g>
                                    <ellipse id="Ellipse 2_23" cx="45.6719" cy="180.883" rx="13.6719" ry="4.88281" fill="#eee"/>
                                    <path id="Exclude_23" d="M32 180.883V184.398C32 187.095 38.1211 189.281 45.6719 189.281C53.2226 189.281 59.3438 187.095 59.3438 184.398V180.925L59.3433 180.925C59.2802 183.602 53.1835 185.766 45.6719 185.766C38.1211 185.766 32 183.58 32 180.883Z" fill="#eee"/>
                                    <path id="Ellipse 1_23" d="M54.8516 180.492C54.8516 180.87 54.6358 181.257 54.1848 181.635C53.7348 182.012 53.072 182.36 52.2358 182.656C50.5652 183.248 48.2445 183.617 45.6719 183.617C43.0992 183.617 40.7785 183.248 39.108 182.656C38.2718 182.36 37.609 182.012 37.159 181.635C36.708 181.257 36.4922 180.87 36.4922 180.492C36.4922 180.114 36.708 179.727 37.159 179.35C37.609 178.973 38.2718 178.625 39.108 178.328C40.7785 177.737 43.0992 177.367 45.6719 177.367C48.2445 177.367 50.5652 177.737 52.2358 178.328C53.072 178.625 53.7348 178.973 54.1848 179.35C54.6358 179.727 54.8516 180.114 54.8516 180.492Z" fill="#CDCBBF" stroke="#999999" strokeWidth="0.390625"/>
                                    <ellipse id="Ellipse 3_23" cx="45.6719" cy="180.883" rx="9.375" ry="3.32031" fill="#fff"/>
                                    <g id="Subtract_23"  opacity="0.4">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M59.333 181.078C59.3401 181.143 59.3438 181.208 59.3438 181.273C59.3438 183.97 53.2226 186.156 45.6719 186.156C38.1211 186.156 32 183.97 32 181.273C32 181.208 32.0036 181.143 32.0107 181.078C32.2978 183.684 38.3043 185.766 45.6719 185.766C53.0395 185.766 59.046 183.684 59.333 181.078Z" fill="#ABABAB"/>
                                    </g>
                                </g>
                            </g>
                            <g className="coin-left" id="coin with bottom_22">
                                <path id="bottom_22" d="M32 177.273V180.789C32 183.486 38.1211 185.672 45.6719 185.672C53.2226 185.672 59.3438 183.486 59.3438 180.789V177.315L59.3433 177.315C59.2802 179.993 53.1835 182.156 45.6719 182.156C38.1211 182.156 32 179.97 32 177.273Z" fill="#999999"/>
                                <g>
                                    <ellipse id="Ellipse 2_24" cx="45.6719" cy="176.883" rx="13.6719" ry="4.88281" fill="#eee"/>
                                    <path id="Exclude_24" d="M32 176.883V180.398C32 183.095 38.1211 185.281 45.6719 185.281C53.2226 185.281 59.3438 183.095 59.3438 180.398V176.925L59.3433 176.925C59.2802 179.602 53.1835 181.766 45.6719 181.766C38.1211 181.766 32 179.58 32 176.883Z" fill="#eee"/>
                                    <path id="Ellipse 1_24" d="M54.8516 176.492C54.8516 176.87 54.6358 177.257 54.1848 177.635C53.7348 178.012 53.072 178.36 52.2358 178.656C50.5652 179.248 48.2445 179.617 45.6719 179.617C43.0992 179.617 40.7785 179.248 39.108 178.656C38.2718 178.36 37.609 178.012 37.159 177.635C36.708 177.257 36.4922 176.87 36.4922 176.492C36.4922 176.114 36.708 175.727 37.159 175.35C37.609 174.973 38.2718 174.625 39.108 174.328C40.7785 173.737 43.0992 173.367 45.6719 173.367C48.2445 173.367 50.5652 173.737 52.2358 174.328C53.072 174.625 53.7348 174.973 54.1848 175.35C54.6358 175.727 54.8516 176.114 54.8516 176.492Z" fill="#CDCBBF" stroke="#999999" strokeWidth="0.390625"/>
                                    <ellipse id="Ellipse 3_24" cx="45.6719" cy="176.883" rx="9.375" ry="3.32031" fill="#fff"/>
                                    <g id="Subtract_24"  opacity="0.4">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M59.333 177.078C59.3401 177.143 59.3438 177.208 59.3438 177.273C59.3438 179.97 53.2226 182.156 45.6719 182.156C38.1211 182.156 32 179.97 32 177.273C32 177.208 32.0036 177.143 32.0107 177.078C32.2978 179.684 38.3043 181.766 45.6719 181.766C53.0395 181.766 59.046 179.684 59.333 177.078Z" fill="#ABABAB"/>
                                    </g>
                                </g>
                            </g>
                            <g className="coin-left" id="coin with bottom_23">
                                <path id="bottom_23" d="M32 173.273V176.789C32 179.486 38.1211 181.672 45.6719 181.672C53.2226 181.672 59.3438 179.486 59.3438 176.789V173.315L59.3433 173.315C59.2802 175.993 53.1835 178.156 45.6719 178.156C38.1211 178.156 32 175.97 32 173.273Z" fill="#999999"/>
                                <g>
                                    <ellipse id="Ellipse 2_25" cx="45.6719" cy="172.883" rx="13.6719" ry="4.88281" fill="#eee"/>
                                    <path id="Exclude_25" d="M32 172.883V176.398C32 179.095 38.1211 181.281 45.6719 181.281C53.2226 181.281 59.3438 179.095 59.3438 176.398V172.925L59.3433 172.925C59.2802 175.602 53.1835 177.766 45.6719 177.766C38.1211 177.766 32 175.58 32 172.883Z" fill="#eee"/>
                                    <path id="Ellipse 1_25" d="M54.8516 172.492C54.8516 172.87 54.6358 173.257 54.1848 173.635C53.7348 174.012 53.072 174.36 52.2358 174.656C50.5652 175.248 48.2445 175.617 45.6719 175.617C43.0992 175.617 40.7785 175.248 39.108 174.656C38.2718 174.36 37.609 174.012 37.159 173.635C36.708 173.257 36.4922 172.87 36.4922 172.492C36.4922 172.114 36.708 171.727 37.159 171.35C37.609 170.973 38.2718 170.625 39.108 170.328C40.7785 169.737 43.0992 169.367 45.6719 169.367C48.2445 169.367 50.5652 169.737 52.2358 170.328C53.072 170.625 53.7348 170.973 54.1848 171.35C54.6358 171.727 54.8516 172.114 54.8516 172.492Z" fill="#CDCBBF" stroke="#999999" strokeWidth="0.390625"/>
                                    <ellipse id="Ellipse 3_25" cx="45.6719" cy="172.883" rx="9.375" ry="3.32031" fill="#fff"/>
                                    <g id="Subtract_25"  opacity="0.4">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M59.333 173.078C59.3401 173.143 59.3438 173.208 59.3438 173.273C59.3438 175.97 53.2226 178.156 45.6719 178.156C38.1211 178.156 32 175.97 32 173.273C32 173.208 32.0036 173.143 32.0107 173.078C32.2978 175.684 38.3043 177.766 45.6719 177.766C53.0395 177.766 59.046 175.684 59.333 173.078Z" fill="#ABABAB"/>
                                    </g>
                                </g>
                            </g>
                            <g className="coin-left" id="coin with bottom_24">
                                <path id="bottom_24" d="M32 169.273V172.789C32 175.486 38.1211 177.672 45.6719 177.672C53.2226 177.672 59.3438 175.486 59.3438 172.789V169.315L59.3433 169.315C59.2802 171.993 53.1835 174.156 45.6719 174.156C38.1211 174.156 32 171.97 32 169.273Z" fill="#999999"/>
                                <g>
                                    <ellipse id="Ellipse 2_26" cx="45.6719" cy="168.883" rx="13.6719" ry="4.88281" fill="#eee"/>
                                    <path id="Exclude_26" d="M32 168.883V172.398C32 175.095 38.1211 177.281 45.6719 177.281C53.2226 177.281 59.3438 175.095 59.3438 172.398V168.925L59.3433 168.925C59.2802 171.602 53.1835 173.766 45.6719 173.766C38.1211 173.766 32 171.58 32 168.883Z" fill="#eee"/>
                                    <path id="Ellipse 1_26" d="M54.8516 168.492C54.8516 168.87 54.6358 169.257 54.1848 169.635C53.7348 170.012 53.072 170.36 52.2358 170.656C50.5652 171.248 48.2445 171.617 45.6719 171.617C43.0992 171.617 40.7785 171.248 39.108 170.656C38.2718 170.36 37.609 170.012 37.159 169.635C36.708 169.257 36.4922 168.87 36.4922 168.492C36.4922 168.114 36.708 167.727 37.159 167.35C37.609 166.973 38.2718 166.625 39.108 166.328C40.7785 165.737 43.0992 165.367 45.6719 165.367C48.2445 165.367 50.5652 165.737 52.2358 166.328C53.072 166.625 53.7348 166.973 54.1848 167.35C54.6358 167.727 54.8516 168.114 54.8516 168.492Z" fill="#CDCBBF" stroke="#999999" strokeWidth="0.390625"/>
                                    <ellipse id="Ellipse 3_26" cx="45.6719" cy="168.883" rx="9.375" ry="3.32031" fill="#fff"/>
                                    <g id="Subtract_26"  opacity="0.4">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M59.333 169.078C59.3401 169.143 59.3438 169.208 59.3438 169.273C59.3438 171.97 53.2226 174.156 45.6719 174.156C38.1211 174.156 32 171.97 32 169.273C32 169.208 32.0036 169.143 32.0107 169.078C32.2978 171.684 38.3043 173.766 45.6719 173.766C53.0395 173.766 59.046 171.684 59.333 169.078Z" fill="#ABABAB"/>
                                    </g>
                                </g>
                            </g>
                            <g className="coin-left" id="coin with bottom_25">
                                <path id="bottom_25" d="M32 165.273V168.789C32 171.486 38.1211 173.672 45.6719 173.672C53.2226 173.672 59.3438 171.486 59.3438 168.789V165.315L59.3433 165.315C59.2802 167.993 53.1835 170.156 45.6719 170.156C38.1211 170.156 32 167.97 32 165.273Z" fill="#999999"/>
                                <g>
                                    <ellipse id="Ellipse 2_27" cx="45.6719" cy="164.883" rx="13.6719" ry="4.88281" fill="#eee"/>
                                    <path id="Exclude_27" d="M32 164.883V168.398C32 171.095 38.1211 173.281 45.6719 173.281C53.2226 173.281 59.3438 171.095 59.3438 168.398V164.925L59.3433 164.925C59.2802 167.602 53.1835 169.766 45.6719 169.766C38.1211 169.766 32 167.58 32 164.883Z" fill="#eee"/>
                                    <path id="Ellipse 1_27" d="M54.8516 164.492C54.8516 164.87 54.6358 165.257 54.1848 165.635C53.7348 166.012 53.072 166.36 52.2358 166.656C50.5652 167.248 48.2445 167.617 45.6719 167.617C43.0992 167.617 40.7785 167.248 39.108 166.656C38.2718 166.36 37.609 166.012 37.159 165.635C36.708 165.257 36.4922 164.87 36.4922 164.492C36.4922 164.114 36.708 163.727 37.159 163.35C37.609 162.973 38.2718 162.625 39.108 162.328C40.7785 161.737 43.0992 161.367 45.6719 161.367C48.2445 161.367 50.5652 161.737 52.2358 162.328C53.072 162.625 53.7348 162.973 54.1848 163.35C54.6358 163.727 54.8516 164.114 54.8516 164.492Z" fill="#CDCBBF" stroke="#999999" strokeWidth="0.390625"/>
                                    <ellipse id="Ellipse 3_27" cx="45.6719" cy="164.883" rx="9.375" ry="3.32031" fill="#fff"/>
                                    <g id="Subtract_27"  opacity="0.4">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M59.333 165.078C59.3401 165.143 59.3438 165.208 59.3438 165.273C59.3438 167.97 53.2226 170.156 45.6719 170.156C38.1211 170.156 32 167.97 32 165.273C32 165.208 32.0036 165.143 32.0107 165.078C32.2978 167.684 38.3043 169.766 45.6719 169.766C53.0395 169.766 59.046 167.684 59.333 165.078Z" fill="#ABABAB"/>
                                    </g>
                                </g>
                            </g>
                            <g className="coin-left" id="coin with bottom_26">
                                <path id="bottom_26" d="M32 161.273V164.789C32 167.486 38.1211 169.672 45.6719 169.672C53.2226 169.672 59.3438 167.486 59.3438 164.789V161.315L59.3433 161.315C59.2802 163.993 53.1835 166.156 45.6719 166.156C38.1211 166.156 32 163.97 32 161.273Z" fill="#999999"/>
                                <g>
                                    <ellipse id="Ellipse 2_28" cx="45.6719" cy="160.883" rx="13.6719" ry="4.88281" fill="#eee"/>
                                    <path id="Exclude_28" d="M32 160.883V164.398C32 167.095 38.1211 169.281 45.6719 169.281C53.2226 169.281 59.3438 167.095 59.3438 164.398V160.925L59.3433 160.925C59.2802 163.602 53.1835 165.766 45.6719 165.766C38.1211 165.766 32 163.58 32 160.883Z" fill="#eee"/>
                                    <path id="Ellipse 1_28" d="M54.8516 160.492C54.8516 160.87 54.6358 161.257 54.1848 161.635C53.7348 162.012 53.072 162.36 52.2358 162.656C50.5652 163.248 48.2445 163.617 45.6719 163.617C43.0992 163.617 40.7785 163.248 39.108 162.656C38.2718 162.36 37.609 162.012 37.159 161.635C36.708 161.257 36.4922 160.87 36.4922 160.492C36.4922 160.114 36.708 159.727 37.159 159.35C37.609 158.973 38.2718 158.625 39.108 158.328C40.7785 157.737 43.0992 157.367 45.6719 157.367C48.2445 157.367 50.5652 157.737 52.2358 158.328C53.072 158.625 53.7348 158.973 54.1848 159.35C54.6358 159.727 54.8516 160.114 54.8516 160.492Z" fill="#CDCBBF" stroke="#999999" strokeWidth="0.390625"/>
                                    <ellipse id="Ellipse 3_28" cx="45.6719" cy="160.883" rx="9.375" ry="3.32031" fill="#fff"/>
                                    <g id="Subtract_28"  opacity="0.4">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M59.333 161.078C59.3401 161.143 59.3438 161.208 59.3438 161.273C59.3438 163.97 53.2226 166.156 45.6719 166.156C38.1211 166.156 32 163.97 32 161.273C32 161.208 32.0036 161.143 32.0107 161.078C32.2978 163.684 38.3043 165.766 45.6719 165.766C53.0395 165.766 59.046 163.684 59.333 161.078Z" fill="#ABABAB"/>
                                    </g>
                                </g>
                            </g>
                            <g className="coin-left" id="coin with bottom_27">
                                <path id="bottom_27" d="M32 157.273V160.789C32 163.486 38.1211 165.672 45.6719 165.672C53.2226 165.672 59.3438 163.486 59.3438 160.789V157.315L59.3433 157.315C59.2802 159.993 53.1835 162.156 45.6719 162.156C38.1211 162.156 32 159.97 32 157.273Z" fill="#999999"/>
                                <g>
                                    <ellipse id="Ellipse 2_29" cx="45.6719" cy="156.883" rx="13.6719" ry="4.88281" fill="#eee"/>
                                    <path id="Exclude_29" d="M32 156.883V160.398C32 163.095 38.1211 165.281 45.6719 165.281C53.2226 165.281 59.3438 163.095 59.3438 160.398V156.925L59.3433 156.925C59.2802 159.602 53.1835 161.766 45.6719 161.766C38.1211 161.766 32 159.58 32 156.883Z" fill="#eee"/>
                                    <path id="Ellipse 1_29" d="M54.8516 156.492C54.8516 156.87 54.6358 157.257 54.1848 157.635C53.7348 158.012 53.072 158.36 52.2358 158.656C50.5652 159.248 48.2445 159.617 45.6719 159.617C43.0992 159.617 40.7785 159.248 39.108 158.656C38.2718 158.36 37.609 158.012 37.159 157.635C36.708 157.257 36.4922 156.87 36.4922 156.492C36.4922 156.114 36.708 155.727 37.159 155.35C37.609 154.973 38.2718 154.625 39.108 154.328C40.7785 153.737 43.0992 153.367 45.6719 153.367C48.2445 153.367 50.5652 153.737 52.2358 154.328C53.072 154.625 53.7348 154.973 54.1848 155.35C54.6358 155.727 54.8516 156.114 54.8516 156.492Z" fill="#CDCBBF" stroke="#999999" strokeWidth="0.390625"/>
                                    <ellipse id="Ellipse 3_29" cx="45.6719" cy="156.883" rx="9.375" ry="3.32031" fill="#fff"/>
                                    <g id="Subtract_29"  opacity="0.4">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M59.333 157.078C59.3401 157.143 59.3438 157.208 59.3438 157.273C59.3438 159.97 53.2226 162.156 45.6719 162.156C38.1211 162.156 32 159.97 32 157.273C32 157.208 32.0036 157.143 32.0107 157.078C32.2978 159.684 38.3043 161.766 45.6719 161.766C53.0395 161.766 59.046 159.684 59.333 157.078Z" fill="#ABABAB"/>
                                    </g>
                                </g>
                            </g>
                            <g className="coin-left" id="coin with bottom_28">
                                <path id="bottom_28" d="M32 153.273V156.789C32 159.486 38.1211 161.672 45.6719 161.672C53.2226 161.672 59.3438 159.486 59.3438 156.789V153.315L59.3433 153.315C59.2802 155.993 53.1835 158.156 45.6719 158.156C38.1211 158.156 32 155.97 32 153.273Z" fill="#999999"/>
                                <g>
                                    <ellipse id="Ellipse 2_30" cx="45.6719" cy="152.883" rx="13.6719" ry="4.88281" fill="#eee"/>
                                    <path id="Exclude_30" d="M32 152.883V156.398C32 159.095 38.1211 161.281 45.6719 161.281C53.2226 161.281 59.3438 159.095 59.3438 156.398V152.925L59.3433 152.925C59.2802 155.602 53.1835 157.766 45.6719 157.766C38.1211 157.766 32 155.58 32 152.883Z" fill="#eee"/>
                                    <path id="Ellipse 1_30" d="M54.8516 152.492C54.8516 152.87 54.6358 153.257 54.1848 153.635C53.7348 154.012 53.072 154.36 52.2358 154.656C50.5652 155.248 48.2445 155.617 45.6719 155.617C43.0992 155.617 40.7785 155.248 39.108 154.656C38.2718 154.36 37.609 154.012 37.159 153.635C36.708 153.257 36.4922 152.87 36.4922 152.492C36.4922 152.114 36.708 151.727 37.159 151.35C37.609 150.973 38.2718 150.625 39.108 150.328C40.7785 149.737 43.0992 149.367 45.6719 149.367C48.2445 149.367 50.5652 149.737 52.2358 150.328C53.072 150.625 53.7348 150.973 54.1848 151.35C54.6358 151.727 54.8516 152.114 54.8516 152.492Z" fill="#CDCBBF" stroke="#999999" strokeWidth="0.390625"/>
                                    <ellipse id="Ellipse 3_30" cx="45.6719" cy="152.883" rx="9.375" ry="3.32031" fill="#fff"/>
                                    <g id="Subtract_30"  opacity="0.4">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M59.333 153.078C59.3401 153.143 59.3438 153.208 59.3438 153.273C59.3438 155.97 53.2226 158.156 45.6719 158.156C38.1211 158.156 32 155.97 32 153.273C32 153.208 32.0036 153.143 32.0107 153.078C32.2978 155.684 38.3043 157.766 45.6719 157.766C53.0395 157.766 59.046 155.684 59.333 153.078Z" fill="#ABABAB"/>
                                    </g>
                                </g>
                            </g>
                            <g className="coin-left" id="coin with bottom_29">
                                <path id="bottom_29" d="M32 149.273V152.789C32 155.486 38.1211 157.672 45.6719 157.672C53.2226 157.672 59.3438 155.486 59.3438 152.789V149.315L59.3433 149.315C59.2802 151.993 53.1835 154.156 45.6719 154.156C38.1211 154.156 32 151.97 32 149.273Z" fill="#999999"/>
                                <g>
                                    <ellipse id="Ellipse 2_31" cx="45.6719" cy="148.883" rx="13.6719" ry="4.88281" fill="#eee"/>
                                    <path id="Exclude_31" d="M32 148.883V152.398C32 155.095 38.1211 157.281 45.6719 157.281C53.2226 157.281 59.3438 155.095 59.3438 152.398V148.925L59.3433 148.925C59.2802 151.602 53.1835 153.766 45.6719 153.766C38.1211 153.766 32 151.58 32 148.883Z" fill="#eee"/>
                                    <path id="Ellipse 1_31" d="M54.8516 148.492C54.8516 148.87 54.6358 149.257 54.1848 149.635C53.7348 150.012 53.072 150.36 52.2358 150.656C50.5652 151.248 48.2445 151.617 45.6719 151.617C43.0992 151.617 40.7785 151.248 39.108 150.656C38.2718 150.36 37.609 150.012 37.159 149.635C36.708 149.257 36.4922 148.87 36.4922 148.492C36.4922 148.114 36.708 147.727 37.159 147.35C37.609 146.973 38.2718 146.625 39.108 146.328C40.7785 145.737 43.0992 145.367 45.6719 145.367C48.2445 145.367 50.5652 145.737 52.2358 146.328C53.072 146.625 53.7348 146.973 54.1848 147.35C54.6358 147.727 54.8516 148.114 54.8516 148.492Z" fill="#CDCBBF" stroke="#999999" strokeWidth="0.390625"/>
                                    <ellipse id="Ellipse 3_31" cx="45.6719" cy="148.883" rx="9.375" ry="3.32031" fill="#fff"/>
                                    <g id="Subtract_31"  opacity="0.4">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M59.333 149.078C59.3401 149.143 59.3438 149.208 59.3438 149.273C59.3438 151.97 53.2226 154.156 45.6719 154.156C38.1211 154.156 32 151.97 32 149.273C32 149.208 32.0036 149.143 32.0107 149.078C32.2978 151.684 38.3043 153.766 45.6719 153.766C53.0395 153.766 59.046 151.684 59.333 149.078Z" fill="#ABABAB"/>
                                    </g>
                                </g>
                            </g>
                            <g className="coin-left" id="coin with bottom_30">
                                <path id="bottom_30" d="M32 145.273V148.789C32 151.486 38.1211 153.672 45.6719 153.672C53.2226 153.672 59.3438 151.486 59.3438 148.789V145.315L59.3433 145.315C59.2802 147.993 53.1835 150.156 45.6719 150.156C38.1211 150.156 32 147.97 32 145.273Z" fill="#999999"/>
                                <g>
                                    <ellipse id="Ellipse 2_32" cx="45.6719" cy="144.883" rx="13.6719" ry="4.88281" fill="#eee"/>
                                    <path id="Exclude_32" d="M32 144.883V148.398C32 151.095 38.1211 153.281 45.6719 153.281C53.2226 153.281 59.3438 151.095 59.3438 148.398V144.925L59.3433 144.925C59.2802 147.602 53.1835 149.766 45.6719 149.766C38.1211 149.766 32 147.58 32 144.883Z" fill="#eee"/>
                                    <path id="Ellipse 1_32" d="M54.8516 144.492C54.8516 144.87 54.6358 145.257 54.1848 145.635C53.7348 146.012 53.072 146.36 52.2358 146.656C50.5652 147.248 48.2445 147.617 45.6719 147.617C43.0992 147.617 40.7785 147.248 39.108 146.656C38.2718 146.36 37.609 146.012 37.159 145.635C36.708 145.257 36.4922 144.87 36.4922 144.492C36.4922 144.114 36.708 143.727 37.159 143.35C37.609 142.973 38.2718 142.625 39.108 142.328C40.7785 141.737 43.0992 141.367 45.6719 141.367C48.2445 141.367 50.5652 141.737 52.2358 142.328C53.072 142.625 53.7348 142.973 54.1848 143.35C54.6358 143.727 54.8516 144.114 54.8516 144.492Z" fill="#CDCBBF" stroke="#999999" strokeWidth="0.390625"/>
                                    <ellipse id="Ellipse 3_32" cx="45.6719" cy="144.883" rx="9.375" ry="3.32031" fill="#fff"/>
                                    <g id="Subtract_32"  opacity="0.4">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M59.333 145.078C59.3401 145.143 59.3438 145.208 59.3438 145.273C59.3438 147.97 53.2226 150.156 45.6719 150.156C38.1211 150.156 32 147.97 32 145.273C32 145.208 32.0036 145.143 32.0107 145.078C32.2978 147.684 38.3043 149.766 45.6719 149.766C53.0395 149.766 59.046 147.684 59.333 145.078Z" fill="#ABABAB"/>
                                    </g>
                                </g>
                            </g>
                            <g className="coin-left" id="coin with bottom_31">
                                <path id="bottom_31" d="M32 141.273V144.789C32 147.486 38.1211 149.672 45.6719 149.672C53.2226 149.672 59.3438 147.486 59.3438 144.789V141.315L59.3433 141.315C59.2802 143.993 53.1835 146.156 45.6719 146.156C38.1211 146.156 32 143.97 32 141.273Z" fill="#999999"/>
                                <g>
                                    <ellipse id="Ellipse 2_33" cx="45.6719" cy="140.883" rx="13.6719" ry="4.88281" fill="#eee"/>
                                    <path id="Exclude_33" d="M32 140.883V144.398C32 147.095 38.1211 149.281 45.6719 149.281C53.2226 149.281 59.3438 147.095 59.3438 144.398V140.925L59.3433 140.925C59.2802 143.602 53.1835 145.766 45.6719 145.766C38.1211 145.766 32 143.58 32 140.883Z" fill="#eee"/>
                                    <path id="Ellipse 1_33" d="M54.8516 140.492C54.8516 140.87 54.6358 141.257 54.1848 141.635C53.7348 142.012 53.072 142.36 52.2358 142.656C50.5652 143.248 48.2445 143.617 45.6719 143.617C43.0992 143.617 40.7785 143.248 39.108 142.656C38.2718 142.36 37.609 142.012 37.159 141.635C36.708 141.257 36.4922 140.87 36.4922 140.492C36.4922 140.114 36.708 139.727 37.159 139.35C37.609 138.973 38.2718 138.625 39.108 138.328C40.7785 137.737 43.0992 137.367 45.6719 137.367C48.2445 137.367 50.5652 137.737 52.2358 138.328C53.072 138.625 53.7348 138.973 54.1848 139.35C54.6358 139.727 54.8516 140.114 54.8516 140.492Z" fill="#CDCBBF" stroke="#999999" strokeWidth="0.390625"/>
                                    <ellipse id="Ellipse 3_33" cx="45.6719" cy="140.883" rx="9.375" ry="3.32031" fill="#fff"/>
                                    <g id="Subtract_33"  opacity="0.4">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M59.333 141.078C59.3401 141.143 59.3438 141.208 59.3438 141.273C59.3438 143.97 53.2226 146.156 45.6719 146.156C38.1211 146.156 32 143.97 32 141.273C32 141.208 32.0036 141.143 32.0107 141.078C32.2978 143.684 38.3043 145.766 45.6719 145.766C53.0395 145.766 59.046 143.684 59.333 141.078Z" fill="#ABABAB"/>
                                    </g>
                                </g>
                            </g>
                            <g className="coin-left" id="coin with bottom_32">
                                <path id="bottom_32" d="M32 137.273V140.789C32 143.486 38.1211 145.672 45.6719 145.672C53.2226 145.672 59.3438 143.486 59.3438 140.789V137.315L59.3433 137.315C59.2802 139.993 53.1835 142.156 45.6719 142.156C38.1211 142.156 32 139.97 32 137.273Z" fill="#999999"/>
                                <g>
                                    <ellipse id="Ellipse 2_34" cx="45.6719" cy="136.883" rx="13.6719" ry="4.88281" fill="#eee"/>
                                    <path id="Exclude_34" d="M32 136.883V140.398C32 143.095 38.1211 145.281 45.6719 145.281C53.2226 145.281 59.3438 143.095 59.3438 140.398V136.925L59.3433 136.925C59.2802 139.602 53.1835 141.766 45.6719 141.766C38.1211 141.766 32 139.58 32 136.883Z" fill="#eee"/>
                                    <path id="Ellipse 1_34" d="M54.8516 136.492C54.8516 136.87 54.6358 137.257 54.1848 137.635C53.7348 138.012 53.072 138.36 52.2358 138.656C50.5652 139.248 48.2445 139.617 45.6719 139.617C43.0992 139.617 40.7785 139.248 39.108 138.656C38.2718 138.36 37.609 138.012 37.159 137.635C36.708 137.257 36.4922 136.87 36.4922 136.492C36.4922 136.114 36.708 135.727 37.159 135.35C37.609 134.973 38.2718 134.625 39.108 134.328C40.7785 133.737 43.0992 133.367 45.6719 133.367C48.2445 133.367 50.5652 133.737 52.2358 134.328C53.072 134.625 53.7348 134.973 54.1848 135.35C54.6358 135.727 54.8516 136.114 54.8516 136.492Z" fill="#CDCBBF" stroke="#999999" strokeWidth="0.390625"/>
                                    <ellipse id="Ellipse 3_34" cx="45.6719" cy="136.883" rx="9.375" ry="3.32031" fill="#fff"/>
                                    <g id="Subtract_34"  opacity="0.4">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M59.333 137.078C59.3401 137.143 59.3438 137.208 59.3438 137.273C59.3438 139.97 53.2226 142.156 45.6719 142.156C38.1211 142.156 32 139.97 32 137.273C32 137.208 32.0036 137.143 32.0107 137.078C32.2978 139.684 38.3043 141.766 45.6719 141.766C53.0395 141.766 59.046 139.684 59.333 137.078Z" fill="#ABABAB"/>
                                    </g>
                                </g>
                            </g>
                        </g>
                        <g id="coin stack_3" className="coin-bar">
                            <g>
                                <ellipse id="Ellipse 2_35" cx="77.6719" cy="208.602" rx="13.6719" ry="4.88281" fill="#eee"/>
                                <path id="Exclude_35" d="M64 208.602V212.117C64 214.814 70.1211 217 77.6719 217C85.2226 217 91.3438 214.814 91.3438 212.117V208.643L91.3433 208.643C91.2802 211.321 85.1835 213.484 77.6719 213.484C70.1211 213.484 64 211.298 64 208.602Z" fill="#eee"/>
                                <path id="Ellipse 1_35" d="M86.8516 208.211C86.8516 208.589 86.6358 208.976 86.1848 209.354C85.7348 209.73 85.072 210.079 84.2358 210.375C82.5652 210.966 80.2445 211.336 77.6719 211.336C75.0992 211.336 72.7785 210.966 71.108 210.375C70.2718 210.079 69.609 209.73 69.159 209.354C68.708 208.976 68.4922 208.589 68.4922 208.211C68.4922 207.833 68.708 207.446 69.159 207.068C69.609 206.691 70.2718 206.343 71.108 206.047C72.7785 205.456 75.0992 205.086 77.6719 205.086C80.2445 205.086 82.5652 205.456 84.2358 206.047C85.072 206.343 85.7348 206.691 86.1848 207.068C86.6358 207.446 86.8516 207.833 86.8516 208.211Z" fill="#CDCBBF" stroke="#999999" strokeWidth="0.390625"/>
                                <ellipse id="Ellipse 3_35" cx="77.6719" cy="208.602" rx="9.375" ry="3.32031" fill="#fff"/>
                                <g id="Subtract_35"  opacity="0.4">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M91.333 208.797C91.3401 208.862 91.3438 208.927 91.3438 208.992C91.3438 211.689 85.2226 213.875 77.6719 213.875C70.1211 213.875 64 211.689 64 208.992C64 208.927 64.0036 208.862 64.0107 208.797C64.2978 211.403 70.3043 213.484 77.6719 213.484C85.0395 213.484 91.046 211.403 91.333 208.797Z" fill="#ABABAB"/>
                                </g>
                            </g>
                            <g className="coin-right" id="coin with bottom_33">
                                <path id="bottom_33" d="M64 205.086V208.602C64 211.298 70.1211 213.484 77.6719 213.484C85.2226 213.484 91.3438 211.298 91.3438 208.602V205.128L91.3433 205.128C91.2802 207.805 85.1835 209.969 77.6719 209.969C70.1211 209.969 64 207.783 64 205.086Z" fill="#999999"/>
                                <g>
                                    <ellipse id="Ellipse 2_36" cx="77.6719" cy="204.695" rx="13.6719" ry="4.88281" fill="#eee"/>
                                    <path id="Exclude_36" d="M64 204.695V208.211C64 210.908 70.1211 213.094 77.6719 213.094C85.2226 213.094 91.3438 210.908 91.3438 208.211V204.737L91.3433 204.737C91.2802 207.415 85.1835 209.578 77.6719 209.578C70.1211 209.578 64 207.392 64 204.695Z" fill="#eee"/>
                                    <path id="Ellipse 1_36" d="M86.8516 204.305C86.8516 204.683 86.6358 205.07 86.1848 205.447C85.7348 205.824 85.072 206.172 84.2358 206.468C82.5652 207.06 80.2445 207.43 77.6719 207.43C75.0992 207.43 72.7785 207.06 71.108 206.468C70.2718 206.172 69.609 205.824 69.159 205.447C68.708 205.07 68.4922 204.683 68.4922 204.305C68.4922 203.927 68.708 203.54 69.159 203.162C69.609 202.785 70.2718 202.437 71.108 202.141C72.7785 201.549 75.0992 201.18 77.6719 201.18C80.2445 201.18 82.5652 201.549 84.2358 202.141C85.072 202.437 85.7348 202.785 86.1848 203.162C86.6358 203.54 86.8516 203.927 86.8516 204.305Z" fill="#CDCBBF" stroke="#999999" strokeWidth="0.390625"/>
                                    <ellipse id="Ellipse 3_36" cx="77.6719" cy="204.695" rx="9.375" ry="3.32031" fill="#fff"/>
                                    <g id="Subtract_36"  opacity="0.4">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M91.333 204.891C91.3401 204.955 91.3438 205.021 91.3438 205.086C91.3438 207.783 85.2226 209.969 77.6719 209.969C70.1211 209.969 64 207.783 64 205.086C64 205.021 64.0036 204.955 64.0107 204.891C64.2978 207.497 70.3043 209.578 77.6719 209.578C85.0395 209.578 91.046 207.497 91.333 204.891Z" fill="#ABABAB"/>
                                    </g>
                                </g>
                            </g>
                            <g className="coin-right" id="coin with bottom_34">
                                <path id="bottom_34" d="M64 201.18V204.695C64 207.392 70.1211 209.578 77.6719 209.578C85.2226 209.578 91.3438 207.392 91.3438 204.695V201.222L91.3433 201.222C91.2802 203.899 85.1835 206.062 77.6719 206.062C70.1211 206.062 64 203.876 64 201.18Z" fill="#999999"/>
                                <g>
                                    <ellipse id="Ellipse 2_37" cx="77.6719" cy="200.789" rx="13.6719" ry="4.88281" fill="#eee"/>
                                    <path id="Exclude_37" d="M64 200.789V204.305C64 207.001 70.1211 209.188 77.6719 209.188C85.2226 209.188 91.3438 207.001 91.3438 204.305V200.831L91.3433 200.831C91.2802 203.508 85.1835 205.672 77.6719 205.672C70.1211 205.672 64 203.486 64 200.789Z" fill="#eee"/>
                                    <path id="Ellipse 1_37" d="M86.8516 200.398C86.8516 200.776 86.6358 201.163 86.1848 201.541C85.7348 201.918 85.072 202.266 84.2358 202.562C82.5652 203.154 80.2445 203.523 77.6719 203.523C75.0992 203.523 72.7785 203.154 71.108 202.562C70.2718 202.266 69.609 201.918 69.159 201.541C68.708 201.163 68.4922 200.776 68.4922 200.398C68.4922 200.02 68.708 199.633 69.159 199.256C69.609 198.879 70.2718 198.531 71.108 198.235C72.7785 197.643 75.0992 197.273 77.6719 197.273C80.2445 197.273 82.5652 197.643 84.2358 198.235C85.072 198.531 85.7348 198.879 86.1848 199.256C86.6358 199.633 86.8516 200.02 86.8516 200.398Z" fill="#CDCBBF" stroke="#999999" strokeWidth="0.390625"/>
                                    <ellipse id="Ellipse 3_37" cx="77.6719" cy="200.789" rx="9.375" ry="3.32031" fill="#fff"/>
                                    <g id="Subtract_37"  opacity="0.4">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M91.333 200.984C91.3401 201.049 91.3438 201.114 91.3438 201.18C91.3438 203.876 85.2226 206.062 77.6719 206.062C70.1211 206.062 64 203.876 64 201.18C64 201.114 64.0036 201.049 64.0107 200.984C64.2978 203.591 70.3043 205.672 77.6719 205.672C85.0395 205.672 91.046 203.591 91.333 200.984Z" fill="#ABABAB"/>
                                    </g>
                                </g>
                            </g>
                            <g className="coin-right" id="coin with bottom_35">
                                <path id="bottom_35" d="M64 197.273V200.789C64 203.486 70.1211 205.672 77.6719 205.672C85.2226 205.672 91.3438 203.486 91.3438 200.789V197.315L91.3433 197.315C91.2802 199.993 85.1835 202.156 77.6719 202.156C70.1211 202.156 64 199.97 64 197.273Z" fill="#999999"/>
                                <g>
                                    <ellipse id="Ellipse 2_38" cx="77.6719" cy="196.883" rx="13.6719" ry="4.88281" fill="#eee"/>
                                    <path id="Exclude_38" d="M64 196.883V200.398C64 203.095 70.1211 205.281 77.6719 205.281C85.2226 205.281 91.3438 203.095 91.3438 200.398V196.925L91.3433 196.925C91.2802 199.602 85.1835 201.766 77.6719 201.766C70.1211 201.766 64 199.58 64 196.883Z" fill="#eee"/>
                                    <path id="Ellipse 1_38" d="M86.8516 196.492C86.8516 196.87 86.6358 197.257 86.1848 197.635C85.7348 198.012 85.072 198.36 84.2358 198.656C82.5652 199.248 80.2445 199.617 77.6719 199.617C75.0992 199.617 72.7785 199.248 71.108 198.656C70.2718 198.36 69.609 198.012 69.159 197.635C68.708 197.257 68.4922 196.87 68.4922 196.492C68.4922 196.114 68.708 195.727 69.159 195.35C69.609 194.973 70.2718 194.625 71.108 194.328C72.7785 193.737 75.0992 193.367 77.6719 193.367C80.2445 193.367 82.5652 193.737 84.2358 194.328C85.072 194.625 85.7348 194.973 86.1848 195.35C86.6358 195.727 86.8516 196.114 86.8516 196.492Z" fill="#CDCBBF" stroke="#999999" strokeWidth="0.390625"/>
                                    <ellipse id="Ellipse 3_38" cx="77.6719" cy="196.883" rx="9.375" ry="3.32031" fill="#fff"/>
                                    <g id="Subtract_38"  opacity="0.4">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M91.333 197.078C91.3401 197.143 91.3438 197.208 91.3438 197.273C91.3438 199.97 85.2226 202.156 77.6719 202.156C70.1211 202.156 64 199.97 64 197.273C64 197.208 64.0036 197.143 64.0107 197.078C64.2978 199.684 70.3043 201.766 77.6719 201.766C85.0395 201.766 91.046 199.684 91.333 197.078Z" fill="#ABABAB"/>
                                    </g>
                                </g>
                            </g>
                            <g className="coin-right" id="coin with bottom_36">
                                <path id="bottom_36" d="M64 193.273V196.789C64 199.486 70.1211 201.672 77.6719 201.672C85.2226 201.672 91.3438 199.486 91.3438 196.789V193.315L91.3433 193.315C91.2802 195.993 85.1835 198.156 77.6719 198.156C70.1211 198.156 64 195.97 64 193.273Z" fill="#999999"/>
                                <g>
                                    <ellipse id="Ellipse 2_39" cx="77.6719" cy="192.883" rx="13.6719" ry="4.88281" fill="#eee"/>
                                    <path id="Exclude_39" d="M64 192.883V196.398C64 199.095 70.1211 201.281 77.6719 201.281C85.2226 201.281 91.3438 199.095 91.3438 196.398V192.925L91.3433 192.925C91.2802 195.602 85.1835 197.766 77.6719 197.766C70.1211 197.766 64 195.58 64 192.883Z" fill="#eee"/>
                                    <path id="Ellipse 1_39" d="M86.8516 192.492C86.8516 192.87 86.6358 193.257 86.1848 193.635C85.7348 194.012 85.072 194.36 84.2358 194.656C82.5652 195.248 80.2445 195.617 77.6719 195.617C75.0992 195.617 72.7785 195.248 71.108 194.656C70.2718 194.36 69.609 194.012 69.159 193.635C68.708 193.257 68.4922 192.87 68.4922 192.492C68.4922 192.114 68.708 191.727 69.159 191.35C69.609 190.973 70.2718 190.625 71.108 190.328C72.7785 189.737 75.0992 189.367 77.6719 189.367C80.2445 189.367 82.5652 189.737 84.2358 190.328C85.072 190.625 85.7348 190.973 86.1848 191.35C86.6358 191.727 86.8516 192.114 86.8516 192.492Z" fill="#CDCBBF" stroke="#999999" strokeWidth="0.390625"/>
                                    <ellipse id="Ellipse 3_39" cx="77.6719" cy="192.883" rx="9.375" ry="3.32031" fill="#fff"/>
                                    <g id="Subtract_39"  opacity="0.4">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M91.333 193.078C91.3401 193.143 91.3438 193.208 91.3438 193.273C91.3438 195.97 85.2226 198.156 77.6719 198.156C70.1211 198.156 64 195.97 64 193.273C64 193.208 64.0036 193.143 64.0107 193.078C64.2978 195.684 70.3043 197.766 77.6719 197.766C85.0395 197.766 91.046 195.684 91.333 193.078Z" fill="#ABABAB"/>
                                    </g>
                                </g>
                            </g>
                            <g className="coin-right" id="coin with bottom_37">
                                <path id="bottom_37" d="M64 189.273V192.789C64 195.486 70.1211 197.672 77.6719 197.672C85.2226 197.672 91.3438 195.486 91.3438 192.789V189.315L91.3433 189.315C91.2802 191.993 85.1835 194.156 77.6719 194.156C70.1211 194.156 64 191.97 64 189.273Z" fill="#999999"/>
                                <g>
                                    <ellipse id="Ellipse 2_40" cx="77.6719" cy="188.883" rx="13.6719" ry="4.88281" fill="#eee"/>
                                    <path id="Exclude_40" d="M64 188.883V192.398C64 195.095 70.1211 197.281 77.6719 197.281C85.2226 197.281 91.3438 195.095 91.3438 192.398V188.925L91.3433 188.925C91.2802 191.602 85.1835 193.766 77.6719 193.766C70.1211 193.766 64 191.58 64 188.883Z" fill="#eee"/>
                                    <path id="Ellipse 1_40" d="M86.8516 188.492C86.8516 188.87 86.6358 189.257 86.1848 189.635C85.7348 190.012 85.072 190.36 84.2358 190.656C82.5652 191.248 80.2445 191.617 77.6719 191.617C75.0992 191.617 72.7785 191.248 71.108 190.656C70.2718 190.36 69.609 190.012 69.159 189.635C68.708 189.257 68.4922 188.87 68.4922 188.492C68.4922 188.114 68.708 187.727 69.159 187.35C69.609 186.973 70.2718 186.625 71.108 186.328C72.7785 185.737 75.0992 185.367 77.6719 185.367C80.2445 185.367 82.5652 185.737 84.2358 186.328C85.072 186.625 85.7348 186.973 86.1848 187.35C86.6358 187.727 86.8516 188.114 86.8516 188.492Z" fill="#CDCBBF" stroke="#999999" strokeWidth="0.390625"/>
                                    <ellipse id="Ellipse 3_40" cx="77.6719" cy="188.883" rx="9.375" ry="3.32031" fill="#fff"/>
                                    <g id="Subtract_40"  opacity="0.4">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M91.333 189.078C91.3401 189.143 91.3438 189.208 91.3438 189.273C91.3438 191.97 85.2226 194.156 77.6719 194.156C70.1211 194.156 64 191.97 64 189.273C64 189.208 64.0036 189.143 64.0107 189.078C64.2978 191.684 70.3043 193.766 77.6719 193.766C85.0395 193.766 91.046 191.684 91.333 189.078Z" fill="#ABABAB"/>
                                    </g>
                                </g>
                            </g>
                            <g className="coin-right" id="coin with bottom_38">
                                <path id="bottom_38" d="M64 185.273V188.789C64 191.486 70.1211 193.672 77.6719 193.672C85.2226 193.672 91.3438 191.486 91.3438 188.789V185.315L91.3433 185.315C91.2802 187.993 85.1835 190.156 77.6719 190.156C70.1211 190.156 64 187.97 64 185.273Z" fill="#999999"/>
                                <g>
                                    <ellipse id="Ellipse 2_41" cx="77.6719" cy="184.883" rx="13.6719" ry="4.88281" fill="#eee"/>
                                    <path id="Exclude_41" d="M64 184.883V188.398C64 191.095 70.1211 193.281 77.6719 193.281C85.2226 193.281 91.3438 191.095 91.3438 188.398V184.925L91.3433 184.925C91.2802 187.602 85.1835 189.766 77.6719 189.766C70.1211 189.766 64 187.58 64 184.883Z" fill="#eee"/>
                                    <path id="Ellipse 1_41" d="M86.8516 184.492C86.8516 184.87 86.6358 185.257 86.1848 185.635C85.7348 186.012 85.072 186.36 84.2358 186.656C82.5652 187.248 80.2445 187.617 77.6719 187.617C75.0992 187.617 72.7785 187.248 71.108 186.656C70.2718 186.36 69.609 186.012 69.159 185.635C68.708 185.257 68.4922 184.87 68.4922 184.492C68.4922 184.114 68.708 183.727 69.159 183.35C69.609 182.973 70.2718 182.625 71.108 182.328C72.7785 181.737 75.0992 181.367 77.6719 181.367C80.2445 181.367 82.5652 181.737 84.2358 182.328C85.072 182.625 85.7348 182.973 86.1848 183.35C86.6358 183.727 86.8516 184.114 86.8516 184.492Z" fill="#CDCBBF" stroke="#999999" strokeWidth="0.390625"/>
                                    <ellipse id="Ellipse 3_41" cx="77.6719" cy="184.883" rx="9.375" ry="3.32031" fill="#fff"/>
                                    <g id="Subtract_41"  opacity="0.4">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M91.333 185.078C91.3401 185.143 91.3438 185.208 91.3438 185.273C91.3438 187.97 85.2226 190.156 77.6719 190.156C70.1211 190.156 64 187.97 64 185.273C64 185.208 64.0036 185.143 64.0107 185.078C64.2978 187.684 70.3043 189.766 77.6719 189.766C85.0395 189.766 91.046 187.684 91.333 185.078Z" fill="#ABABAB"/>
                                    </g>
                                </g>
                            </g>
                            <g className="coin-right" id="coin with bottom_39">
                                <path id="bottom_39" d="M64 181.273V184.789C64 187.486 70.1211 189.672 77.6719 189.672C85.2226 189.672 91.3438 187.486 91.3438 184.789V181.315L91.3433 181.315C91.2802 183.993 85.1835 186.156 77.6719 186.156C70.1211 186.156 64 183.97 64 181.273Z" fill="#999999"/>
                                <g>
                                    <ellipse id="Ellipse 2_42" cx="77.6719" cy="180.883" rx="13.6719" ry="4.88281" fill="#eee"/>
                                    <path id="Exclude_42" d="M64 180.883V184.398C64 187.095 70.1211 189.281 77.6719 189.281C85.2226 189.281 91.3438 187.095 91.3438 184.398V180.925L91.3433 180.925C91.2802 183.602 85.1835 185.766 77.6719 185.766C70.1211 185.766 64 183.58 64 180.883Z" fill="#eee"/>
                                    <path id="Ellipse 1_42" d="M86.8516 180.492C86.8516 180.87 86.6358 181.257 86.1848 181.635C85.7348 182.012 85.072 182.36 84.2358 182.656C82.5652 183.248 80.2445 183.617 77.6719 183.617C75.0992 183.617 72.7785 183.248 71.108 182.656C70.2718 182.36 69.609 182.012 69.159 181.635C68.708 181.257 68.4922 180.87 68.4922 180.492C68.4922 180.114 68.708 179.727 69.159 179.35C69.609 178.973 70.2718 178.625 71.108 178.328C72.7785 177.737 75.0992 177.367 77.6719 177.367C80.2445 177.367 82.5652 177.737 84.2358 178.328C85.072 178.625 85.7348 178.973 86.1848 179.35C86.6358 179.727 86.8516 180.114 86.8516 180.492Z" fill="#CDCBBF" stroke="#999999" strokeWidth="0.390625"/>
                                    <ellipse id="Ellipse 3_42" cx="77.6719" cy="180.883" rx="9.375" ry="3.32031" fill="#fff"/>
                                    <g id="Subtract_42"  opacity="0.4">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M91.333 181.078C91.3401 181.143 91.3438 181.208 91.3438 181.273C91.3438 183.97 85.2226 186.156 77.6719 186.156C70.1211 186.156 64 183.97 64 181.273C64 181.208 64.0036 181.143 64.0107 181.078C64.2978 183.684 70.3043 185.766 77.6719 185.766C85.0395 185.766 91.046 183.684 91.333 181.078Z" fill="#ABABAB"/>
                                    </g>
                                </g>
                            </g>
                            <g className="coin-right" id="coin with bottom_40">
                                <path id="bottom_40" d="M64 177.273V180.789C64 183.486 70.1211 185.672 77.6719 185.672C85.2226 185.672 91.3438 183.486 91.3438 180.789V177.315L91.3433 177.315C91.2802 179.993 85.1835 182.156 77.6719 182.156C70.1211 182.156 64 179.97 64 177.273Z" fill="#999999"/>
                                <g>
                                    <ellipse id="Ellipse 2_43" cx="77.6719" cy="176.883" rx="13.6719" ry="4.88281" fill="#eee"/>
                                    <path id="Exclude_43" d="M64 176.883V180.398C64 183.095 70.1211 185.281 77.6719 185.281C85.2226 185.281 91.3438 183.095 91.3438 180.398V176.925L91.3433 176.925C91.2802 179.602 85.1835 181.766 77.6719 181.766C70.1211 181.766 64 179.58 64 176.883Z" fill="#eee"/>
                                    <path id="Ellipse 1_43" d="M86.8516 176.492C86.8516 176.87 86.6358 177.257 86.1848 177.635C85.7348 178.012 85.072 178.36 84.2358 178.656C82.5652 179.248 80.2445 179.617 77.6719 179.617C75.0992 179.617 72.7785 179.248 71.108 178.656C70.2718 178.36 69.609 178.012 69.159 177.635C68.708 177.257 68.4922 176.87 68.4922 176.492C68.4922 176.114 68.708 175.727 69.159 175.35C69.609 174.973 70.2718 174.625 71.108 174.328C72.7785 173.737 75.0992 173.367 77.6719 173.367C80.2445 173.367 82.5652 173.737 84.2358 174.328C85.072 174.625 85.7348 174.973 86.1848 175.35C86.6358 175.727 86.8516 176.114 86.8516 176.492Z" fill="#CDCBBF" stroke="#999999" strokeWidth="0.390625"/>
                                    <ellipse id="Ellipse 3_43" cx="77.6719" cy="176.883" rx="9.375" ry="3.32031" fill="#fff"/>
                                    <g id="Subtract_43"  opacity="0.4">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M91.333 177.078C91.3401 177.143 91.3438 177.208 91.3438 177.273C91.3438 179.97 85.2226 182.156 77.6719 182.156C70.1211 182.156 64 179.97 64 177.273C64 177.208 64.0036 177.143 64.0107 177.078C64.2978 179.684 70.3043 181.766 77.6719 181.766C85.0395 181.766 91.046 179.684 91.333 177.078Z" fill="#ABABAB"/>
                                    </g>
                                </g>
                            </g>
                            <g className="coin-right" id="coin with bottom_41">
                                <path id="bottom_41" d="M64 173.273V176.789C64 179.486 70.1211 181.672 77.6719 181.672C85.2226 181.672 91.3438 179.486 91.3438 176.789V173.315L91.3433 173.315C91.2802 175.993 85.1835 178.156 77.6719 178.156C70.1211 178.156 64 175.97 64 173.273Z" fill="#999999"/>
                                <g>
                                    <ellipse id="Ellipse 2_44" cx="77.6719" cy="172.883" rx="13.6719" ry="4.88281" fill="#eee"/>
                                    <path id="Exclude_44" d="M64 172.883V176.398C64 179.095 70.1211 181.281 77.6719 181.281C85.2226 181.281 91.3438 179.095 91.3438 176.398V172.925L91.3433 172.925C91.2802 175.602 85.1835 177.766 77.6719 177.766C70.1211 177.766 64 175.58 64 172.883Z" fill="#eee"/>
                                    <path id="Ellipse 1_44" d="M86.8516 172.492C86.8516 172.87 86.6358 173.257 86.1848 173.635C85.7348 174.012 85.072 174.36 84.2358 174.656C82.5652 175.248 80.2445 175.617 77.6719 175.617C75.0992 175.617 72.7785 175.248 71.108 174.656C70.2718 174.36 69.609 174.012 69.159 173.635C68.708 173.257 68.4922 172.87 68.4922 172.492C68.4922 172.114 68.708 171.727 69.159 171.35C69.609 170.973 70.2718 170.625 71.108 170.328C72.7785 169.737 75.0992 169.367 77.6719 169.367C80.2445 169.367 82.5652 169.737 84.2358 170.328C85.072 170.625 85.7348 170.973 86.1848 171.35C86.6358 171.727 86.8516 172.114 86.8516 172.492Z" fill="#CDCBBF" stroke="#999999" strokeWidth="0.390625"/>
                                    <ellipse id="Ellipse 3_44" cx="77.6719" cy="172.883" rx="9.375" ry="3.32031" fill="#fff"/>
                                    <g id="Subtract_44"  opacity="0.4">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M91.333 173.078C91.3401 173.143 91.3438 173.208 91.3438 173.273C91.3438 175.97 85.2226 178.156 77.6719 178.156C70.1211 178.156 64 175.97 64 173.273C64 173.208 64.0036 173.143 64.0107 173.078C64.2978 175.684 70.3043 177.766 77.6719 177.766C85.0395 177.766 91.046 175.684 91.333 173.078Z" fill="#ABABAB"/>
                                    </g>
                                </g>
                            </g>
                            <g className="coin-right" id="coin with bottom_42">
                                <path id="bottom_42" d="M64 169.273V172.789C64 175.486 70.1211 177.672 77.6719 177.672C85.2226 177.672 91.3438 175.486 91.3438 172.789V169.315L91.3433 169.315C91.2802 171.993 85.1835 174.156 77.6719 174.156C70.1211 174.156 64 171.97 64 169.273Z" fill="#999999"/>
                                <g>
                                    <ellipse id="Ellipse 2_45" cx="77.6719" cy="168.883" rx="13.6719" ry="4.88281" fill="#eee"/>
                                    <path id="Exclude_45" d="M64 168.883V172.398C64 175.095 70.1211 177.281 77.6719 177.281C85.2226 177.281 91.3438 175.095 91.3438 172.398V168.925L91.3433 168.925C91.2802 171.602 85.1835 173.766 77.6719 173.766C70.1211 173.766 64 171.58 64 168.883Z" fill="#eee"/>
                                    <path id="Ellipse 1_45" d="M86.8516 168.492C86.8516 168.87 86.6358 169.257 86.1848 169.635C85.7348 170.012 85.072 170.36 84.2358 170.656C82.5652 171.248 80.2445 171.617 77.6719 171.617C75.0992 171.617 72.7785 171.248 71.108 170.656C70.2718 170.36 69.609 170.012 69.159 169.635C68.708 169.257 68.4922 168.87 68.4922 168.492C68.4922 168.114 68.708 167.727 69.159 167.35C69.609 166.973 70.2718 166.625 71.108 166.328C72.7785 165.737 75.0992 165.367 77.6719 165.367C80.2445 165.367 82.5652 165.737 84.2358 166.328C85.072 166.625 85.7348 166.973 86.1848 167.35C86.6358 167.727 86.8516 168.114 86.8516 168.492Z" fill="#CDCBBF" stroke="#999999" strokeWidth="0.390625"/>
                                    <ellipse id="Ellipse 3_45" cx="77.6719" cy="168.883" rx="9.375" ry="3.32031" fill="#fff"/>
                                    <g id="Subtract_45"  opacity="0.4">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M91.333 169.078C91.3401 169.143 91.3438 169.208 91.3438 169.273C91.3438 171.97 85.2226 174.156 77.6719 174.156C70.1211 174.156 64 171.97 64 169.273C64 169.208 64.0036 169.143 64.0107 169.078C64.2978 171.684 70.3043 173.766 77.6719 173.766C85.0395 173.766 91.046 171.684 91.333 169.078Z" fill="#ABABAB"/>
                                    </g>
                                </g>
                            </g>
                            <g className="coin-right" id="coin with bottom_43">
                                <path id="bottom_43" d="M64 165.273V168.789C64 171.486 70.1211 173.672 77.6719 173.672C85.2226 173.672 91.3438 171.486 91.3438 168.789V165.315L91.3433 165.315C91.2802 167.993 85.1835 170.156 77.6719 170.156C70.1211 170.156 64 167.97 64 165.273Z" fill="#999999"/>
                                <g>
                                    <ellipse id="Ellipse 2_46" cx="77.6719" cy="164.883" rx="13.6719" ry="4.88281" fill="#eee"/>
                                    <path id="Exclude_46" d="M64 164.883V168.398C64 171.095 70.1211 173.281 77.6719 173.281C85.2226 173.281 91.3438 171.095 91.3438 168.398V164.925L91.3433 164.925C91.2802 167.602 85.1835 169.766 77.6719 169.766C70.1211 169.766 64 167.58 64 164.883Z" fill="#eee"/>
                                    <path id="Ellipse 1_46" d="M86.8516 164.492C86.8516 164.87 86.6358 165.257 86.1848 165.635C85.7348 166.012 85.072 166.36 84.2358 166.656C82.5652 167.248 80.2445 167.617 77.6719 167.617C75.0992 167.617 72.7785 167.248 71.108 166.656C70.2718 166.36 69.609 166.012 69.159 165.635C68.708 165.257 68.4922 164.87 68.4922 164.492C68.4922 164.114 68.708 163.727 69.159 163.35C69.609 162.973 70.2718 162.625 71.108 162.328C72.7785 161.737 75.0992 161.367 77.6719 161.367C80.2445 161.367 82.5652 161.737 84.2358 162.328C85.072 162.625 85.7348 162.973 86.1848 163.35C86.6358 163.727 86.8516 164.114 86.8516 164.492Z" fill="#CDCBBF" stroke="#999999" strokeWidth="0.390625"/>
                                    <ellipse id="Ellipse 3_46" cx="77.6719" cy="164.883" rx="9.375" ry="3.32031" fill="#fff"/>
                                    <g id="Subtract_46"  opacity="0.4">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M91.333 165.078C91.3401 165.143 91.3438 165.208 91.3438 165.273C91.3438 167.97 85.2226 170.156 77.6719 170.156C70.1211 170.156 64 167.97 64 165.273C64 165.208 64.0036 165.143 64.0107 165.078C64.2978 167.684 70.3043 169.766 77.6719 169.766C85.0395 169.766 91.046 167.684 91.333 165.078Z" fill="#ABABAB"/>
                                    </g>
                                </g>
                            </g>
                            <g className="coin-right" id="coin with bottom_44">
                                <path id="bottom_44" d="M64 161.273V164.789C64 167.486 70.1211 169.672 77.6719 169.672C85.2226 169.672 91.3438 167.486 91.3438 164.789V161.315L91.3433 161.315C91.2802 163.993 85.1835 166.156 77.6719 166.156C70.1211 166.156 64 163.97 64 161.273Z" fill="#999999"/>
                                <g>
                                    <ellipse id="Ellipse 2_47" cx="77.6719" cy="160.883" rx="13.6719" ry="4.88281" fill="#eee"/>
                                    <path id="Exclude_47" d="M64 160.883V164.398C64 167.095 70.1211 169.281 77.6719 169.281C85.2226 169.281 91.3438 167.095 91.3438 164.398V160.925L91.3433 160.925C91.2802 163.602 85.1835 165.766 77.6719 165.766C70.1211 165.766 64 163.58 64 160.883Z" fill="#eee"/>
                                    <path id="Ellipse 1_47" d="M86.8516 160.492C86.8516 160.87 86.6358 161.257 86.1848 161.635C85.7348 162.012 85.072 162.36 84.2358 162.656C82.5652 163.248 80.2445 163.617 77.6719 163.617C75.0992 163.617 72.7785 163.248 71.108 162.656C70.2718 162.36 69.609 162.012 69.159 161.635C68.708 161.257 68.4922 160.87 68.4922 160.492C68.4922 160.114 68.708 159.727 69.159 159.35C69.609 158.973 70.2718 158.625 71.108 158.328C72.7785 157.737 75.0992 157.367 77.6719 157.367C80.2445 157.367 82.5652 157.737 84.2358 158.328C85.072 158.625 85.7348 158.973 86.1848 159.35C86.6358 159.727 86.8516 160.114 86.8516 160.492Z" fill="#CDCBBF" stroke="#999999" strokeWidth="0.390625"/>
                                    <ellipse id="Ellipse 3_47" cx="77.6719" cy="160.883" rx="9.375" ry="3.32031" fill="#fff"/>
                                    <g id="Subtract_47"  opacity="0.4">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M91.333 161.078C91.3401 161.143 91.3438 161.208 91.3438 161.273C91.3438 163.97 85.2226 166.156 77.6719 166.156C70.1211 166.156 64 163.97 64 161.273C64 161.208 64.0036 161.143 64.0107 161.078C64.2978 163.684 70.3043 165.766 77.6719 165.766C85.0395 165.766 91.046 163.684 91.333 161.078Z" fill="#ABABAB"/>
                                    </g>
                                </g>
                            </g>
                        </g>
                        <g id="coin stack_4" className="coin-bar">
                            <g>
                                <ellipse id="Ellipse 2_48" cx="109.672" cy="208.602" rx="13.6719" ry="4.88281" fill="#eee"/>
                                <path id="Exclude_48" d="M96 208.602V212.117C96 214.814 102.121 217 109.672 217C117.223 217 123.344 214.814 123.344 212.117V208.643L123.343 208.643C123.28 211.321 117.183 213.484 109.672 213.484C102.121 213.484 96 211.298 96 208.602Z" fill="#eee"/>
                                <path id="Ellipse 1_48" d="M118.852 208.211C118.852 208.589 118.636 208.976 118.185 209.354C117.735 209.73 117.072 210.079 116.236 210.375C114.565 210.966 112.245 211.336 109.672 211.336C107.099 211.336 104.779 210.966 103.108 210.375C102.272 210.079 101.609 209.73 101.159 209.354C100.708 208.976 100.492 208.589 100.492 208.211C100.492 207.833 100.708 207.446 101.159 207.068C101.609 206.691 102.272 206.343 103.108 206.047C104.779 205.456 107.099 205.086 109.672 205.086C112.245 205.086 114.565 205.456 116.236 206.047C117.072 206.343 117.735 206.691 118.185 207.068C118.636 207.446 118.852 207.833 118.852 208.211Z" fill="#CDCBBF" stroke="#999999" strokeWidth="0.390625"/>
                                <ellipse id="Ellipse 3_48" cx="109.672" cy="208.602" rx="9.375" ry="3.32031" fill="#fff"/>
                                <g id="Subtract_48"  opacity="0.4">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M123.333 208.797C123.34 208.862 123.344 208.927 123.344 208.992C123.344 211.689 117.223 213.875 109.672 213.875C102.121 213.875 96 211.689 96 208.992C96 208.927 96.0036 208.862 96.0107 208.797C96.2978 211.403 102.304 213.484 109.672 213.484C117.039 213.484 123.046 211.403 123.333 208.797Z" fill="#ABABAB"/>
                                </g>
                            </g>
                            <g className="coin-right" id="coin with bottom_45">
                                <path id="bottom_45" d="M96 205.086V208.602C96 211.298 102.121 213.484 109.672 213.484C117.223 213.484 123.344 211.298 123.344 208.602V205.128L123.343 205.128C123.28 207.805 117.183 209.969 109.672 209.969C102.121 209.969 96 207.783 96 205.086Z" fill="#999999"/>
                                <g>
                                    <ellipse id="Ellipse 2_49" cx="109.672" cy="204.695" rx="13.6719" ry="4.88281" fill="#eee"/>
                                    <path id="Exclude_49" d="M96 204.695V208.211C96 210.908 102.121 213.094 109.672 213.094C117.223 213.094 123.344 210.908 123.344 208.211V204.737L123.343 204.737C123.28 207.415 117.183 209.578 109.672 209.578C102.121 209.578 96 207.392 96 204.695Z" fill="#eee"/>
                                    <path id="Ellipse 1_49" d="M118.852 204.305C118.852 204.683 118.636 205.07 118.185 205.447C117.735 205.824 117.072 206.172 116.236 206.468C114.565 207.06 112.245 207.43 109.672 207.43C107.099 207.43 104.779 207.06 103.108 206.468C102.272 206.172 101.609 205.824 101.159 205.447C100.708 205.07 100.492 204.683 100.492 204.305C100.492 203.927 100.708 203.54 101.159 203.162C101.609 202.785 102.272 202.437 103.108 202.141C104.779 201.549 107.099 201.18 109.672 201.18C112.245 201.18 114.565 201.549 116.236 202.141C117.072 202.437 117.735 202.785 118.185 203.162C118.636 203.54 118.852 203.927 118.852 204.305Z" fill="#CDCBBF" stroke="#999999" strokeWidth="0.390625"/>
                                    <ellipse id="Ellipse 3_49" cx="109.672" cy="204.695" rx="9.375" ry="3.32031" fill="#fff"/>
                                    <g id="Subtract_49"  opacity="0.4">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M123.333 204.891C123.34 204.955 123.344 205.021 123.344 205.086C123.344 207.783 117.223 209.969 109.672 209.969C102.121 209.969 96 207.783 96 205.086C96 205.021 96.0036 204.955 96.0107 204.891C96.2978 207.497 102.304 209.578 109.672 209.578C117.039 209.578 123.046 207.497 123.333 204.891Z" fill="#ABABAB"/>
                                    </g>
                                </g>
                            </g>
                            <g className="coin-right" id="coin with bottom_46">
                                <path id="bottom_46" d="M96 201.18V204.695C96 207.392 102.121 209.578 109.672 209.578C117.223 209.578 123.344 207.392 123.344 204.695V201.222L123.343 201.222C123.28 203.899 117.183 206.062 109.672 206.062C102.121 206.062 96 203.876 96 201.18Z" fill="#999999"/>
                                <g>
                                    <ellipse id="Ellipse 2_50" cx="109.672" cy="200.789" rx="13.6719" ry="4.88281" fill="#eee"/>
                                    <path id="Exclude_50" d="M96 200.789V204.305C96 207.001 102.121 209.188 109.672 209.188C117.223 209.188 123.344 207.001 123.344 204.305V200.831L123.343 200.831C123.28 203.508 117.183 205.672 109.672 205.672C102.121 205.672 96 203.486 96 200.789Z" fill="#eee"/>
                                    <path id="Ellipse 1_50" d="M118.852 200.398C118.852 200.776 118.636 201.163 118.185 201.541C117.735 201.918 117.072 202.266 116.236 202.562C114.565 203.154 112.245 203.523 109.672 203.523C107.099 203.523 104.779 203.154 103.108 202.562C102.272 202.266 101.609 201.918 101.159 201.541C100.708 201.163 100.492 200.776 100.492 200.398C100.492 200.02 100.708 199.633 101.159 199.256C101.609 198.879 102.272 198.531 103.108 198.235C104.779 197.643 107.099 197.273 109.672 197.273C112.245 197.273 114.565 197.643 116.236 198.235C117.072 198.531 117.735 198.879 118.185 199.256C118.636 199.633 118.852 200.02 118.852 200.398Z" fill="#CDCBBF" stroke="#999999" strokeWidth="0.390625"/>
                                    <ellipse id="Ellipse 3_50" cx="109.672" cy="200.789" rx="9.375" ry="3.32031" fill="#fff"/>
                                    <g id="Subtract_50"  opacity="0.4">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M123.333 200.984C123.34 201.049 123.344 201.114 123.344 201.18C123.344 203.876 117.223 206.062 109.672 206.062C102.121 206.062 96 203.876 96 201.18C96 201.114 96.0036 201.049 96.0107 200.984C96.2978 203.591 102.304 205.672 109.672 205.672C117.039 205.672 123.046 203.591 123.333 200.984Z" fill="#ABABAB"/>
                                    </g>
                                </g>
                            </g>
                            <g className="coin-right" id="coin with bottom_47">
                                <path id="bottom_47" d="M96 197.273V200.789C96 203.486 102.121 205.672 109.672 205.672C117.223 205.672 123.344 203.486 123.344 200.789V197.315L123.343 197.315C123.28 199.993 117.183 202.156 109.672 202.156C102.121 202.156 96 199.97 96 197.273Z" fill="#999999"/>
                                <g>
                                    <ellipse id="Ellipse 2_51" cx="109.672" cy="196.883" rx="13.6719" ry="4.88281" fill="#eee"/>
                                    <path id="Exclude_51" d="M96 196.883V200.398C96 203.095 102.121 205.281 109.672 205.281C117.223 205.281 123.344 203.095 123.344 200.398V196.925L123.343 196.925C123.28 199.602 117.183 201.766 109.672 201.766C102.121 201.766 96 199.58 96 196.883Z" fill="#eee"/>
                                    <path id="Ellipse 1_51" d="M118.852 196.492C118.852 196.87 118.636 197.257 118.185 197.635C117.735 198.012 117.072 198.36 116.236 198.656C114.565 199.248 112.245 199.617 109.672 199.617C107.099 199.617 104.779 199.248 103.108 198.656C102.272 198.36 101.609 198.012 101.159 197.635C100.708 197.257 100.492 196.87 100.492 196.492C100.492 196.114 100.708 195.727 101.159 195.35C101.609 194.973 102.272 194.625 103.108 194.328C104.779 193.737 107.099 193.367 109.672 193.367C112.245 193.367 114.565 193.737 116.236 194.328C117.072 194.625 117.735 194.973 118.185 195.35C118.636 195.727 118.852 196.114 118.852 196.492Z" fill="#CDCBBF" stroke="#999999" strokeWidth="0.390625"/>
                                    <ellipse id="Ellipse 3_51" cx="109.672" cy="196.883" rx="9.375" ry="3.32031" fill="#fff"/>
                                    <g id="Subtract_51"  opacity="0.4">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M123.333 197.078C123.34 197.143 123.344 197.208 123.344 197.273C123.344 199.97 117.223 202.156 109.672 202.156C102.121 202.156 96 199.97 96 197.273C96 197.208 96.0036 197.143 96.0107 197.078C96.2978 199.684 102.304 201.766 109.672 201.766C117.039 201.766 123.046 199.684 123.333 197.078Z" fill="#ABABAB"/>
                                    </g>
                                </g>
                            </g>
                            <g className="coin-right" id="coin with bottom_48">
                                <path id="bottom_48" d="M96 193.273V196.789C96 199.486 102.121 201.672 109.672 201.672C117.223 201.672 123.344 199.486 123.344 196.789V193.315L123.343 193.315C123.28 195.993 117.183 198.156 109.672 198.156C102.121 198.156 96 195.97 96 193.273Z" fill="#999999"/>
                                <g>
                                    <ellipse id="Ellipse 2_52" cx="109.672" cy="192.883" rx="13.6719" ry="4.88281" fill="#eee"/>
                                    <path id="Exclude_52" d="M96 192.883V196.398C96 199.095 102.121 201.281 109.672 201.281C117.223 201.281 123.344 199.095 123.344 196.398V192.925L123.343 192.925C123.28 195.602 117.183 197.766 109.672 197.766C102.121 197.766 96 195.58 96 192.883Z" fill="#eee"/>
                                    <path id="Ellipse 1_52" d="M118.852 192.492C118.852 192.87 118.636 193.257 118.185 193.635C117.735 194.012 117.072 194.36 116.236 194.656C114.565 195.248 112.245 195.617 109.672 195.617C107.099 195.617 104.779 195.248 103.108 194.656C102.272 194.36 101.609 194.012 101.159 193.635C100.708 193.257 100.492 192.87 100.492 192.492C100.492 192.114 100.708 191.727 101.159 191.35C101.609 190.973 102.272 190.625 103.108 190.328C104.779 189.737 107.099 189.367 109.672 189.367C112.245 189.367 114.565 189.737 116.236 190.328C117.072 190.625 117.735 190.973 118.185 191.35C118.636 191.727 118.852 192.114 118.852 192.492Z" fill="#CDCBBF" stroke="#999999" strokeWidth="0.390625"/>
                                    <ellipse id="Ellipse 3_52" cx="109.672" cy="192.883" rx="9.375" ry="3.32031" fill="#fff"/>
                                    <g id="Subtract_52"  opacity="0.4">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M123.333 193.078C123.34 193.143 123.344 193.208 123.344 193.273C123.344 195.97 117.223 198.156 109.672 198.156C102.121 198.156 96 195.97 96 193.273C96 193.208 96.0036 193.143 96.0107 193.078C96.2978 195.684 102.304 197.766 109.672 197.766C117.039 197.766 123.046 195.684 123.333 193.078Z" fill="#ABABAB"/>
                                    </g>
                                </g>
                            </g>
                            <g className="coin-right" id="coin with bottom_49">
                                <path id="bottom_49" d="M96 189.273V192.789C96 195.486 102.121 197.672 109.672 197.672C117.223 197.672 123.344 195.486 123.344 192.789V189.315L123.343 189.315C123.28 191.993 117.183 194.156 109.672 194.156C102.121 194.156 96 191.97 96 189.273Z" fill="#999999"/>
                                <g>
                                    <ellipse id="Ellipse 2_53" cx="109.672" cy="188.883" rx="13.6719" ry="4.88281" fill="#eee"/>
                                    <path id="Exclude_53" d="M96 188.883V192.398C96 195.095 102.121 197.281 109.672 197.281C117.223 197.281 123.344 195.095 123.344 192.398V188.925L123.343 188.925C123.28 191.602 117.183 193.766 109.672 193.766C102.121 193.766 96 191.58 96 188.883Z" fill="#eee"/>
                                    <path id="Ellipse 1_53" d="M118.852 188.492C118.852 188.87 118.636 189.257 118.185 189.635C117.735 190.012 117.072 190.36 116.236 190.656C114.565 191.248 112.245 191.617 109.672 191.617C107.099 191.617 104.779 191.248 103.108 190.656C102.272 190.36 101.609 190.012 101.159 189.635C100.708 189.257 100.492 188.87 100.492 188.492C100.492 188.114 100.708 187.727 101.159 187.35C101.609 186.973 102.272 186.625 103.108 186.328C104.779 185.737 107.099 185.367 109.672 185.367C112.245 185.367 114.565 185.737 116.236 186.328C117.072 186.625 117.735 186.973 118.185 187.35C118.636 187.727 118.852 188.114 118.852 188.492Z" fill="#CDCBBF" stroke="#999999" strokeWidth="0.390625"/>
                                    <ellipse id="Ellipse 3_53" cx="109.672" cy="188.883" rx="9.375" ry="3.32031" fill="#fff"/>
                                    <g id="Subtract_53"  opacity="0.4">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M123.333 189.078C123.34 189.143 123.344 189.208 123.344 189.273C123.344 191.97 117.223 194.156 109.672 194.156C102.121 194.156 96 191.97 96 189.273C96 189.208 96.0036 189.143 96.0107 189.078C96.2978 191.684 102.304 193.766 109.672 193.766C117.039 193.766 123.046 191.684 123.333 189.078Z" fill="#ABABAB"/>
                                    </g>
                                </g>
                            </g>
                            <g className="coin-right" id="coin with bottom_50">
                                <path id="bottom_50" d="M96 185.273V188.789C96 191.486 102.121 193.672 109.672 193.672C117.223 193.672 123.344 191.486 123.344 188.789V185.315L123.343 185.315C123.28 187.993 117.183 190.156 109.672 190.156C102.121 190.156 96 187.97 96 185.273Z" fill="#999999"/>
                                <g>
                                    <ellipse id="Ellipse 2_54" cx="109.672" cy="184.883" rx="13.6719" ry="4.88281" fill="#eee"/>
                                    <path id="Exclude_54" d="M96 184.883V188.398C96 191.095 102.121 193.281 109.672 193.281C117.223 193.281 123.344 191.095 123.344 188.398V184.925L123.343 184.925C123.28 187.602 117.183 189.766 109.672 189.766C102.121 189.766 96 187.58 96 184.883Z" fill="#eee"/>
                                    <path id="Ellipse 1_54" d="M118.852 184.492C118.852 184.87 118.636 185.257 118.185 185.635C117.735 186.012 117.072 186.36 116.236 186.656C114.565 187.248 112.245 187.617 109.672 187.617C107.099 187.617 104.779 187.248 103.108 186.656C102.272 186.36 101.609 186.012 101.159 185.635C100.708 185.257 100.492 184.87 100.492 184.492C100.492 184.114 100.708 183.727 101.159 183.35C101.609 182.973 102.272 182.625 103.108 182.328C104.779 181.737 107.099 181.367 109.672 181.367C112.245 181.367 114.565 181.737 116.236 182.328C117.072 182.625 117.735 182.973 118.185 183.35C118.636 183.727 118.852 184.114 118.852 184.492Z" fill="#CDCBBF" stroke="#999999" strokeWidth="0.390625"/>
                                    <ellipse id="Ellipse 3_54" cx="109.672" cy="184.883" rx="9.375" ry="3.32031" fill="#fff"/>
                                    <g id="Subtract_54"  opacity="0.4">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M123.333 185.078C123.34 185.143 123.344 185.208 123.344 185.273C123.344 187.97 117.223 190.156 109.672 190.156C102.121 190.156 96 187.97 96 185.273C96 185.208 96.0036 185.143 96.0107 185.078C96.2978 187.684 102.304 189.766 109.672 189.766C117.039 189.766 123.046 187.684 123.333 185.078Z" fill="#ABABAB"/>
                                    </g>
                                </g>
                            </g>
                            <g className="coin-right" id="coin with bottom_51">
                                <path id="bottom_51" d="M96 181.273V184.789C96 187.486 102.121 189.672 109.672 189.672C117.223 189.672 123.344 187.486 123.344 184.789V181.315L123.343 181.315C123.28 183.993 117.183 186.156 109.672 186.156C102.121 186.156 96 183.97 96 181.273Z" fill="#999999"/>
                                <g>
                                    <ellipse id="Ellipse 2_55" cx="109.672" cy="180.883" rx="13.6719" ry="4.88281" fill="#eee"/>
                                    <path id="Exclude_55" d="M96 180.883V184.398C96 187.095 102.121 189.281 109.672 189.281C117.223 189.281 123.344 187.095 123.344 184.398V180.925L123.343 180.925C123.28 183.602 117.183 185.766 109.672 185.766C102.121 185.766 96 183.58 96 180.883Z" fill="#eee"/>
                                    <path id="Ellipse 1_55" d="M118.852 180.492C118.852 180.87 118.636 181.257 118.185 181.635C117.735 182.012 117.072 182.36 116.236 182.656C114.565 183.248 112.245 183.617 109.672 183.617C107.099 183.617 104.779 183.248 103.108 182.656C102.272 182.36 101.609 182.012 101.159 181.635C100.708 181.257 100.492 180.87 100.492 180.492C100.492 180.114 100.708 179.727 101.159 179.35C101.609 178.973 102.272 178.625 103.108 178.328C104.779 177.737 107.099 177.367 109.672 177.367C112.245 177.367 114.565 177.737 116.236 178.328C117.072 178.625 117.735 178.973 118.185 179.35C118.636 179.727 118.852 180.114 118.852 180.492Z" fill="#CDCBBF" stroke="#999999" strokeWidth="0.390625"/>
                                    <ellipse id="Ellipse 3_55" cx="109.672" cy="180.883" rx="9.375" ry="3.32031" fill="#fff"/>
                                    <g id="Subtract_55"  opacity="0.4">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M123.333 181.078C123.34 181.143 123.344 181.208 123.344 181.273C123.344 183.97 117.223 186.156 109.672 186.156C102.121 186.156 96 183.97 96 181.273C96 181.208 96.0036 181.143 96.0107 181.078C96.2978 183.684 102.304 185.766 109.672 185.766C117.039 185.766 123.046 183.684 123.333 181.078Z" fill="#ABABAB"/>
                                    </g>
                                </g>
                            </g>
                            <g className="coin-right" id="coin with bottom_52">
                                <path id="bottom_52" d="M96 177.273V180.789C96 183.486 102.121 185.672 109.672 185.672C117.223 185.672 123.344 183.486 123.344 180.789V177.315L123.343 177.315C123.28 179.993 117.183 182.156 109.672 182.156C102.121 182.156 96 179.97 96 177.273Z" fill="#999999"/>
                                <g>
                                    <ellipse id="Ellipse 2_56" cx="109.672" cy="176.883" rx="13.6719" ry="4.88281" fill="#eee"/>
                                    <path id="Exclude_56" d="M96 176.883V180.398C96 183.095 102.121 185.281 109.672 185.281C117.223 185.281 123.344 183.095 123.344 180.398V176.925L123.343 176.925C123.28 179.602 117.183 181.766 109.672 181.766C102.121 181.766 96 179.58 96 176.883Z" fill="#eee"/>
                                    <path id="Ellipse 1_56" d="M118.852 176.492C118.852 176.87 118.636 177.257 118.185 177.635C117.735 178.012 117.072 178.36 116.236 178.656C114.565 179.248 112.245 179.617 109.672 179.617C107.099 179.617 104.779 179.248 103.108 178.656C102.272 178.36 101.609 178.012 101.159 177.635C100.708 177.257 100.492 176.87 100.492 176.492C100.492 176.114 100.708 175.727 101.159 175.35C101.609 174.973 102.272 174.625 103.108 174.328C104.779 173.737 107.099 173.367 109.672 173.367C112.245 173.367 114.565 173.737 116.236 174.328C117.072 174.625 117.735 174.973 118.185 175.35C118.636 175.727 118.852 176.114 118.852 176.492Z" fill="#CDCBBF" stroke="#999999" strokeWidth="0.390625"/>
                                    <ellipse id="Ellipse 3_56" cx="109.672" cy="176.883" rx="9.375" ry="3.32031" fill="#fff"/>
                                    <g id="Subtract_56"  opacity="0.4">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M123.333 177.078C123.34 177.143 123.344 177.208 123.344 177.273C123.344 179.97 117.223 182.156 109.672 182.156C102.121 182.156 96 179.97 96 177.273C96 177.208 96.0036 177.143 96.0107 177.078C96.2978 179.684 102.304 181.766 109.672 181.766C117.039 181.766 123.046 179.684 123.333 177.078Z" fill="#ABABAB"/>
                                    </g>
                                </g>
                            </g>
                            <g className="coin-right" id="coin with bottom_53">
                                <path id="bottom_53" d="M96 173.273V176.789C96 179.486 102.121 181.672 109.672 181.672C117.223 181.672 123.344 179.486 123.344 176.789V173.315L123.343 173.315C123.28 175.993 117.183 178.156 109.672 178.156C102.121 178.156 96 175.97 96 173.273Z" fill="#999999"/>
                                <g>
                                    <ellipse id="Ellipse 2_57" cx="109.672" cy="172.883" rx="13.6719" ry="4.88281" fill="#eee"/>
                                    <path id="Exclude_57" d="M96 172.883V176.398C96 179.095 102.121 181.281 109.672 181.281C117.223 181.281 123.344 179.095 123.344 176.398V172.925L123.343 172.925C123.28 175.602 117.183 177.766 109.672 177.766C102.121 177.766 96 175.58 96 172.883Z" fill="#eee"/>
                                    <path id="Ellipse 1_57" d="M118.852 172.492C118.852 172.87 118.636 173.257 118.185 173.635C117.735 174.012 117.072 174.36 116.236 174.656C114.565 175.248 112.245 175.617 109.672 175.617C107.099 175.617 104.779 175.248 103.108 174.656C102.272 174.36 101.609 174.012 101.159 173.635C100.708 173.257 100.492 172.87 100.492 172.492C100.492 172.114 100.708 171.727 101.159 171.35C101.609 170.973 102.272 170.625 103.108 170.328C104.779 169.737 107.099 169.367 109.672 169.367C112.245 169.367 114.565 169.737 116.236 170.328C117.072 170.625 117.735 170.973 118.185 171.35C118.636 171.727 118.852 172.114 118.852 172.492Z" fill="#CDCBBF" stroke="#999999" strokeWidth="0.390625"/>
                                    <ellipse id="Ellipse 3_57" cx="109.672" cy="172.883" rx="9.375" ry="3.32031" fill="#fff"/>
                                    <g id="Subtract_57"  opacity="0.4">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M123.333 173.078C123.34 173.143 123.344 173.208 123.344 173.273C123.344 175.97 117.223 178.156 109.672 178.156C102.121 178.156 96 175.97 96 173.273C96 173.208 96.0036 173.143 96.0107 173.078C96.2978 175.684 102.304 177.766 109.672 177.766C117.039 177.766 123.046 175.684 123.333 173.078Z" fill="#ABABAB"/>
                                    </g>
                                </g>
                            </g>
                            <g className="coin-right" id="coin with bottom_54">
                                <path id="bottom_54" d="M96 169.273V172.789C96 175.486 102.121 177.672 109.672 177.672C117.223 177.672 123.344 175.486 123.344 172.789V169.315L123.343 169.315C123.28 171.993 117.183 174.156 109.672 174.156C102.121 174.156 96 171.97 96 169.273Z" fill="#999999"/>
                                <g>
                                    <ellipse id="Ellipse 2_58" cx="109.672" cy="168.883" rx="13.6719" ry="4.88281" fill="#eee"/>
                                    <path id="Exclude_58" d="M96 168.883V172.398C96 175.095 102.121 177.281 109.672 177.281C117.223 177.281 123.344 175.095 123.344 172.398V168.925L123.343 168.925C123.28 171.602 117.183 173.766 109.672 173.766C102.121 173.766 96 171.58 96 168.883Z" fill="#eee"/>
                                    <path id="Ellipse 1_58" d="M118.852 168.492C118.852 168.87 118.636 169.257 118.185 169.635C117.735 170.012 117.072 170.36 116.236 170.656C114.565 171.248 112.245 171.617 109.672 171.617C107.099 171.617 104.779 171.248 103.108 170.656C102.272 170.36 101.609 170.012 101.159 169.635C100.708 169.257 100.492 168.87 100.492 168.492C100.492 168.114 100.708 167.727 101.159 167.35C101.609 166.973 102.272 166.625 103.108 166.328C104.779 165.737 107.099 165.367 109.672 165.367C112.245 165.367 114.565 165.737 116.236 166.328C117.072 166.625 117.735 166.973 118.185 167.35C118.636 167.727 118.852 168.114 118.852 168.492Z" fill="#CDCBBF" stroke="#999999" strokeWidth="0.390625"/>
                                    <ellipse id="Ellipse 3_58" cx="109.672" cy="168.883" rx="9.375" ry="3.32031" fill="#fff"/>
                                    <g id="Subtract_58"  opacity="0.4">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M123.333 169.078C123.34 169.143 123.344 169.208 123.344 169.273C123.344 171.97 117.223 174.156 109.672 174.156C102.121 174.156 96 171.97 96 169.273C96 169.208 96.0036 169.143 96.0107 169.078C96.2978 171.684 102.304 173.766 109.672 173.766C117.039 173.766 123.046 171.684 123.333 169.078Z" fill="#ABABAB"/>
                                    </g>
                                </g>
                            </g>
                            <g className="coin-right" id="coin with bottom_55">
                                <path id="bottom_55" d="M96 165.273V168.789C96 171.486 102.121 173.672 109.672 173.672C117.223 173.672 123.344 171.486 123.344 168.789V165.315L123.343 165.315C123.28 167.993 117.183 170.156 109.672 170.156C102.121 170.156 96 167.97 96 165.273Z" fill="#999999"/>
                                <g>
                                    <ellipse id="Ellipse 2_59" cx="109.672" cy="164.883" rx="13.6719" ry="4.88281" fill="#eee"/>
                                    <path id="Exclude_59" d="M96 164.883V168.398C96 171.095 102.121 173.281 109.672 173.281C117.223 173.281 123.344 171.095 123.344 168.398V164.925L123.343 164.925C123.28 167.602 117.183 169.766 109.672 169.766C102.121 169.766 96 167.58 96 164.883Z" fill="#eee"/>
                                    <path id="Ellipse 1_59" d="M118.852 164.492C118.852 164.87 118.636 165.257 118.185 165.635C117.735 166.012 117.072 166.36 116.236 166.656C114.565 167.248 112.245 167.617 109.672 167.617C107.099 167.617 104.779 167.248 103.108 166.656C102.272 166.36 101.609 166.012 101.159 165.635C100.708 165.257 100.492 164.87 100.492 164.492C100.492 164.114 100.708 163.727 101.159 163.35C101.609 162.973 102.272 162.625 103.108 162.328C104.779 161.737 107.099 161.367 109.672 161.367C112.245 161.367 114.565 161.737 116.236 162.328C117.072 162.625 117.735 162.973 118.185 163.35C118.636 163.727 118.852 164.114 118.852 164.492Z" fill="#CDCBBF" stroke="#999999" strokeWidth="0.390625"/>
                                    <ellipse id="Ellipse 3_59" cx="109.672" cy="164.883" rx="9.375" ry="3.32031" fill="#fff"/>
                                    <g id="Subtract_59"  opacity="0.4">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M123.333 165.078C123.34 165.143 123.344 165.208 123.344 165.273C123.344 167.97 117.223 170.156 109.672 170.156C102.121 170.156 96 167.97 96 165.273C96 165.208 96.0036 165.143 96.0107 165.078C96.2978 167.684 102.304 169.766 109.672 169.766C117.039 169.766 123.046 167.684 123.333 165.078Z" fill="#ABABAB"/>
                                    </g>
                                </g>
                            </g>
                            <g className="coin-right" id="coin with bottom_56">
                                <path id="bottom_56" d="M96 161.273V164.789C96 167.486 102.121 169.672 109.672 169.672C117.223 169.672 123.344 167.486 123.344 164.789V161.315L123.343 161.315C123.28 163.993 117.183 166.156 109.672 166.156C102.121 166.156 96 163.97 96 161.273Z" fill="#999999"/>
                                <g>
                                    <ellipse id="Ellipse 2_60" cx="109.672" cy="160.883" rx="13.6719" ry="4.88281" fill="#eee"/>
                                    <path id="Exclude_60" d="M96 160.883V164.398C96 167.095 102.121 169.281 109.672 169.281C117.223 169.281 123.344 167.095 123.344 164.398V160.925L123.343 160.925C123.28 163.602 117.183 165.766 109.672 165.766C102.121 165.766 96 163.58 96 160.883Z" fill="#eee"/>
                                    <path id="Ellipse 1_60" d="M118.852 160.492C118.852 160.87 118.636 161.257 118.185 161.635C117.735 162.012 117.072 162.36 116.236 162.656C114.565 163.248 112.245 163.617 109.672 163.617C107.099 163.617 104.779 163.248 103.108 162.656C102.272 162.36 101.609 162.012 101.159 161.635C100.708 161.257 100.492 160.87 100.492 160.492C100.492 160.114 100.708 159.727 101.159 159.35C101.609 158.973 102.272 158.625 103.108 158.328C104.779 157.737 107.099 157.367 109.672 157.367C112.245 157.367 114.565 157.737 116.236 158.328C117.072 158.625 117.735 158.973 118.185 159.35C118.636 159.727 118.852 160.114 118.852 160.492Z" fill="#CDCBBF" stroke="#999999" strokeWidth="0.390625"/>
                                    <ellipse id="Ellipse 3_60" cx="109.672" cy="160.883" rx="9.375" ry="3.32031" fill="#fff"/>
                                    <g id="Subtract_60"  opacity="0.4">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M123.333 161.078C123.34 161.143 123.344 161.208 123.344 161.273C123.344 163.97 117.223 166.156 109.672 166.156C102.121 166.156 96 163.97 96 161.273C96 161.208 96.0036 161.143 96.0107 161.078C96.2978 163.684 102.304 165.766 109.672 165.766C117.039 165.766 123.046 163.684 123.333 161.078Z" fill="#ABABAB"/>
                                    </g>
                                </g>
                            </g>
                            <g className="coin-right" id="coin with bottom_57">
                                <path id="bottom_57" d="M96 157.273V160.789C96 163.486 102.121 165.672 109.672 165.672C117.223 165.672 123.344 163.486 123.344 160.789V157.315L123.343 157.315C123.28 159.993 117.183 162.156 109.672 162.156C102.121 162.156 96 159.97 96 157.273Z" fill="#999999"/>
                                <g>
                                    <ellipse id="Ellipse 2_61" cx="109.672" cy="156.883" rx="13.6719" ry="4.88281" fill="#eee"/>
                                    <path id="Exclude_61" d="M96 156.883V160.398C96 163.095 102.121 165.281 109.672 165.281C117.223 165.281 123.344 163.095 123.344 160.398V156.925L123.343 156.925C123.28 159.602 117.183 161.766 109.672 161.766C102.121 161.766 96 159.58 96 156.883Z" fill="#eee"/>
                                    <path id="Ellipse 1_61" d="M118.852 156.492C118.852 156.87 118.636 157.257 118.185 157.635C117.735 158.012 117.072 158.36 116.236 158.656C114.565 159.248 112.245 159.617 109.672 159.617C107.099 159.617 104.779 159.248 103.108 158.656C102.272 158.36 101.609 158.012 101.159 157.635C100.708 157.257 100.492 156.87 100.492 156.492C100.492 156.114 100.708 155.727 101.159 155.35C101.609 154.973 102.272 154.625 103.108 154.328C104.779 153.737 107.099 153.367 109.672 153.367C112.245 153.367 114.565 153.737 116.236 154.328C117.072 154.625 117.735 154.973 118.185 155.35C118.636 155.727 118.852 156.114 118.852 156.492Z" fill="#CDCBBF" stroke="#999999" strokeWidth="0.390625"/>
                                    <ellipse id="Ellipse 3_61" cx="109.672" cy="156.883" rx="9.375" ry="3.32031" fill="#fff"/>
                                    <g id="Subtract_61"  opacity="0.4">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M123.333 157.078C123.34 157.143 123.344 157.208 123.344 157.273C123.344 159.97 117.223 162.156 109.672 162.156C102.121 162.156 96 159.97 96 157.273C96 157.208 96.0036 157.143 96.0107 157.078C96.2978 159.684 102.304 161.766 109.672 161.766C117.039 161.766 123.046 159.684 123.333 157.078Z" fill="#ABABAB"/>
                                    </g>
                                </g>
                            </g>
                            <g className="coin-right" id="coin with bottom_58">
                                <path id="bottom_58" d="M96 153.273V156.789C96 159.486 102.121 161.672 109.672 161.672C117.223 161.672 123.344 159.486 123.344 156.789V153.315L123.343 153.315C123.28 155.993 117.183 158.156 109.672 158.156C102.121 158.156 96 155.97 96 153.273Z" fill="#999999"/>
                                <g>
                                    <ellipse id="Ellipse 2_62" cx="109.672" cy="152.883" rx="13.6719" ry="4.88281" fill="#eee"/>
                                    <path id="Exclude_62" d="M96 152.883V156.398C96 159.095 102.121 161.281 109.672 161.281C117.223 161.281 123.344 159.095 123.344 156.398V152.925L123.343 152.925C123.28 155.602 117.183 157.766 109.672 157.766C102.121 157.766 96 155.58 96 152.883Z" fill="#eee"/>
                                    <path id="Ellipse 1_62" d="M118.852 152.492C118.852 152.87 118.636 153.257 118.185 153.635C117.735 154.012 117.072 154.36 116.236 154.656C114.565 155.248 112.245 155.617 109.672 155.617C107.099 155.617 104.779 155.248 103.108 154.656C102.272 154.36 101.609 154.012 101.159 153.635C100.708 153.257 100.492 152.87 100.492 152.492C100.492 152.114 100.708 151.727 101.159 151.35C101.609 150.973 102.272 150.625 103.108 150.328C104.779 149.737 107.099 149.367 109.672 149.367C112.245 149.367 114.565 149.737 116.236 150.328C117.072 150.625 117.735 150.973 118.185 151.35C118.636 151.727 118.852 152.114 118.852 152.492Z" fill="#CDCBBF" stroke="#999999" strokeWidth="0.390625"/>
                                    <ellipse id="Ellipse 3_62" cx="109.672" cy="152.883" rx="9.375" ry="3.32031" fill="#fff"/>
                                    <g id="Subtract_62"  opacity="0.4">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M123.333 153.078C123.34 153.143 123.344 153.208 123.344 153.273C123.344 155.97 117.223 158.156 109.672 158.156C102.121 158.156 96 155.97 96 153.273C96 153.208 96.0036 153.143 96.0107 153.078C96.2978 155.684 102.304 157.766 109.672 157.766C117.039 157.766 123.046 155.684 123.333 153.078Z" fill="#ABABAB"/>
                                    </g>
                                </g>
                            </g>
                            <g className="coin-right" id="coin with bottom_59">
                                <path id="bottom_59" d="M96 149.273V152.789C96 155.486 102.121 157.672 109.672 157.672C117.223 157.672 123.344 155.486 123.344 152.789V149.315L123.343 149.315C123.28 151.993 117.183 154.156 109.672 154.156C102.121 154.156 96 151.97 96 149.273Z" fill="#999999"/>
                                <g>
                                    <ellipse id="Ellipse 2_63" cx="109.672" cy="148.883" rx="13.6719" ry="4.88281" fill="#eee"/>
                                    <path id="Exclude_63" d="M96 148.883V152.398C96 155.095 102.121 157.281 109.672 157.281C117.223 157.281 123.344 155.095 123.344 152.398V148.925L123.343 148.925C123.28 151.602 117.183 153.766 109.672 153.766C102.121 153.766 96 151.58 96 148.883Z" fill="#eee"/>
                                    <path id="Ellipse 1_63" d="M118.852 148.492C118.852 148.87 118.636 149.257 118.185 149.635C117.735 150.012 117.072 150.36 116.236 150.656C114.565 151.248 112.245 151.617 109.672 151.617C107.099 151.617 104.779 151.248 103.108 150.656C102.272 150.36 101.609 150.012 101.159 149.635C100.708 149.257 100.492 148.87 100.492 148.492C100.492 148.114 100.708 147.727 101.159 147.35C101.609 146.973 102.272 146.625 103.108 146.328C104.779 145.737 107.099 145.367 109.672 145.367C112.245 145.367 114.565 145.737 116.236 146.328C117.072 146.625 117.735 146.973 118.185 147.35C118.636 147.727 118.852 148.114 118.852 148.492Z" fill="#CDCBBF" stroke="#999999" strokeWidth="0.390625"/>
                                    <ellipse id="Ellipse 3_63" cx="109.672" cy="148.883" rx="9.375" ry="3.32031" fill="#fff"/>
                                    <g id="Subtract_63"  opacity="0.4">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M123.333 149.078C123.34 149.143 123.344 149.208 123.344 149.273C123.344 151.97 117.223 154.156 109.672 154.156C102.121 154.156 96 151.97 96 149.273C96 149.208 96.0036 149.143 96.0107 149.078C96.2978 151.684 102.304 153.766 109.672 153.766C117.039 153.766 123.046 151.684 123.333 149.078Z" fill="#ABABAB"/>
                                    </g>
                                </g>
                            </g>
                            <g className="coin-right" id="coin with bottom_60">
                                <path id="bottom_60" d="M96 145.273V148.789C96 151.486 102.121 153.672 109.672 153.672C117.223 153.672 123.344 151.486 123.344 148.789V145.315L123.343 145.315C123.28 147.993 117.183 150.156 109.672 150.156C102.121 150.156 96 147.97 96 145.273Z" fill="#999999"/>
                                <g>
                                    <ellipse id="Ellipse 2_64" cx="109.672" cy="144.883" rx="13.6719" ry="4.88281" fill="#eee"/>
                                    <path id="Exclude_64" d="M96 144.883V148.398C96 151.095 102.121 153.281 109.672 153.281C117.223 153.281 123.344 151.095 123.344 148.398V144.925L123.343 144.925C123.28 147.602 117.183 149.766 109.672 149.766C102.121 149.766 96 147.58 96 144.883Z" fill="#eee"/>
                                    <path id="Ellipse 1_64" d="M118.852 144.492C118.852 144.87 118.636 145.257 118.185 145.635C117.735 146.012 117.072 146.36 116.236 146.656C114.565 147.248 112.245 147.617 109.672 147.617C107.099 147.617 104.779 147.248 103.108 146.656C102.272 146.36 101.609 146.012 101.159 145.635C100.708 145.257 100.492 144.87 100.492 144.492C100.492 144.114 100.708 143.727 101.159 143.35C101.609 142.973 102.272 142.625 103.108 142.328C104.779 141.737 107.099 141.367 109.672 141.367C112.245 141.367 114.565 141.737 116.236 142.328C117.072 142.625 117.735 142.973 118.185 143.35C118.636 143.727 118.852 144.114 118.852 144.492Z" fill="#CDCBBF" stroke="#999999" strokeWidth="0.390625"/>
                                    <ellipse id="Ellipse 3_64" cx="109.672" cy="144.883" rx="9.375" ry="3.32031" fill="#fff"/>
                                    <g id="Subtract_64"  opacity="0.4">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M123.333 145.078C123.34 145.143 123.344 145.208 123.344 145.273C123.344 147.97 117.223 150.156 109.672 150.156C102.121 150.156 96 147.97 96 145.273C96 145.208 96.0036 145.143 96.0107 145.078C96.2978 147.684 102.304 149.766 109.672 149.766C117.039 149.766 123.046 147.684 123.333 145.078Z" fill="#ABABAB"/>
                                    </g>
                                </g>
                            </g>
                            <g className="coin-right" id="coin with bottom_61">
                                <path id="bottom_61" d="M96 141.273V144.789C96 147.486 102.121 149.672 109.672 149.672C117.223 149.672 123.344 147.486 123.344 144.789V141.315L123.343 141.315C123.28 143.993 117.183 146.156 109.672 146.156C102.121 146.156 96 143.97 96 141.273Z" fill="#999999"/>
                                <g>
                                    <ellipse id="Ellipse 2_65" cx="109.672" cy="140.883" rx="13.6719" ry="4.88281" fill="#eee"/>
                                    <path id="Exclude_65" d="M96 140.883V144.398C96 147.095 102.121 149.281 109.672 149.281C117.223 149.281 123.344 147.095 123.344 144.398V140.925L123.343 140.925C123.28 143.602 117.183 145.766 109.672 145.766C102.121 145.766 96 143.58 96 140.883Z" fill="#eee"/>
                                    <path id="Ellipse 1_65" d="M118.852 140.492C118.852 140.87 118.636 141.257 118.185 141.635C117.735 142.012 117.072 142.36 116.236 142.656C114.565 143.248 112.245 143.617 109.672 143.617C107.099 143.617 104.779 143.248 103.108 142.656C102.272 142.36 101.609 142.012 101.159 141.635C100.708 141.257 100.492 140.87 100.492 140.492C100.492 140.114 100.708 139.727 101.159 139.35C101.609 138.973 102.272 138.625 103.108 138.328C104.779 137.737 107.099 137.367 109.672 137.367C112.245 137.367 114.565 137.737 116.236 138.328C117.072 138.625 117.735 138.973 118.185 139.35C118.636 139.727 118.852 140.114 118.852 140.492Z" fill="#CDCBBF" stroke="#999999" strokeWidth="0.390625"/>
                                    <ellipse id="Ellipse 3_65" cx="109.672" cy="140.883" rx="9.375" ry="3.32031" fill="#fff"/>
                                    <g id="Subtract_65"  opacity="0.4">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M123.333 141.078C123.34 141.143 123.344 141.208 123.344 141.273C123.344 143.97 117.223 146.156 109.672 146.156C102.121 146.156 96 143.97 96 141.273C96 141.208 96.0036 141.143 96.0107 141.078C96.2978 143.684 102.304 145.766 109.672 145.766C117.039 145.766 123.046 143.684 123.333 141.078Z" fill="#ABABAB"/>
                                    </g>
                                </g>
                            </g>
                            <g className="coin-right" id="coin with bottom_62">
                                <path id="bottom_62" d="M96 137.273V140.789C96 143.486 102.121 145.672 109.672 145.672C117.223 145.672 123.344 143.486 123.344 140.789V137.315L123.343 137.315C123.28 139.993 117.183 142.156 109.672 142.156C102.121 142.156 96 139.97 96 137.273Z" fill="#999999"/>
                                <g>
                                    <ellipse id="Ellipse 2_66" cx="109.672" cy="136.883" rx="13.6719" ry="4.88281" fill="#eee"/>
                                    <path id="Exclude_66" d="M96 136.883V140.398C96 143.095 102.121 145.281 109.672 145.281C117.223 145.281 123.344 143.095 123.344 140.398V136.925L123.343 136.925C123.28 139.602 117.183 141.766 109.672 141.766C102.121 141.766 96 139.58 96 136.883Z" fill="#eee"/>
                                    <path id="Ellipse 1_66" d="M118.852 136.492C118.852 136.87 118.636 137.257 118.185 137.635C117.735 138.012 117.072 138.36 116.236 138.656C114.565 139.248 112.245 139.617 109.672 139.617C107.099 139.617 104.779 139.248 103.108 138.656C102.272 138.36 101.609 138.012 101.159 137.635C100.708 137.257 100.492 136.87 100.492 136.492C100.492 136.114 100.708 135.727 101.159 135.35C101.609 134.973 102.272 134.625 103.108 134.328C104.779 133.737 107.099 133.367 109.672 133.367C112.245 133.367 114.565 133.737 116.236 134.328C117.072 134.625 117.735 134.973 118.185 135.35C118.636 135.727 118.852 136.114 118.852 136.492Z" fill="#CDCBBF" stroke="#999999" strokeWidth="0.390625"/>
                                    <ellipse id="Ellipse 3_66" cx="109.672" cy="136.883" rx="9.375" ry="3.32031" fill="#fff"/>
                                    <g id="Subtract_66"  opacity="0.4">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M123.333 137.078C123.34 137.143 123.344 137.208 123.344 137.273C123.344 139.97 117.223 142.156 109.672 142.156C102.121 142.156 96 139.97 96 137.273C96 137.208 96.0036 137.143 96.0107 137.078C96.2978 139.684 102.304 141.766 109.672 141.766C117.039 141.766 123.046 139.684 123.333 137.078Z" fill="#ABABAB"/>
                                    </g>
                                </g>
                            </g>
                            <g className="coin-right" id="coin with bottom_63">
                                <path id="bottom_63" d="M96 133.273V136.789C96 139.486 102.121 141.672 109.672 141.672C117.223 141.672 123.344 139.486 123.344 136.789V133.315L123.343 133.315C123.28 135.993 117.183 138.156 109.672 138.156C102.121 138.156 96 135.97 96 133.273Z" fill="#999999"/>
                                <g>
                                    <ellipse id="Ellipse 2_67" cx="109.672" cy="132.883" rx="13.6719" ry="4.88281" fill="#eee"/>
                                    <path id="Exclude_67" d="M96 132.883V136.398C96 139.095 102.121 141.281 109.672 141.281C117.223 141.281 123.344 139.095 123.344 136.398V132.925L123.343 132.925C123.28 135.602 117.183 137.766 109.672 137.766C102.121 137.766 96 135.58 96 132.883Z" fill="#eee"/>
                                    <path id="Ellipse 1_67" d="M118.852 132.492C118.852 132.87 118.636 133.257 118.185 133.635C117.735 134.012 117.072 134.36 116.236 134.656C114.565 135.248 112.245 135.617 109.672 135.617C107.099 135.617 104.779 135.248 103.108 134.656C102.272 134.36 101.609 134.012 101.159 133.635C100.708 133.257 100.492 132.87 100.492 132.492C100.492 132.114 100.708 131.727 101.159 131.35C101.609 130.973 102.272 130.625 103.108 130.328C104.779 129.737 107.099 129.367 109.672 129.367C112.245 129.367 114.565 129.737 116.236 130.328C117.072 130.625 117.735 130.973 118.185 131.35C118.636 131.727 118.852 132.114 118.852 132.492Z" fill="#CDCBBF" stroke="#999999" strokeWidth="0.390625"/>
                                    <ellipse id="Ellipse 3_67" cx="109.672" cy="132.883" rx="9.375" ry="3.32031" fill="#fff"/>
                                    <g id="Subtract_67"  opacity="0.4">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M123.333 133.078C123.34 133.143 123.344 133.208 123.344 133.273C123.344 135.97 117.223 138.156 109.672 138.156C102.121 138.156 96 135.97 96 133.273C96 133.208 96.0036 133.143 96.0107 133.078C96.2978 135.684 102.304 137.766 109.672 137.766C117.039 137.766 123.046 135.684 123.333 133.078Z" fill="#ABABAB"/>
                                    </g>
                                </g>
                            </g>
                            <g className="coin-right" id="coin with bottom_64">
                                <path id="bottom_64" d="M96 129.273V132.789C96 135.486 102.121 137.672 109.672 137.672C117.223 137.672 123.344 135.486 123.344 132.789V129.315L123.343 129.315C123.28 131.993 117.183 134.156 109.672 134.156C102.121 134.156 96 131.97 96 129.273Z" fill="#999999"/>
                                <g>
                                    <ellipse id="Ellipse 2_68" cx="109.672" cy="128.883" rx="13.6719" ry="4.88281" fill="#eee"/>
                                    <path id="Exclude_68" d="M96 128.883V132.398C96 135.095 102.121 137.281 109.672 137.281C117.223 137.281 123.344 135.095 123.344 132.398V128.925L123.343 128.925C123.28 131.602 117.183 133.766 109.672 133.766C102.121 133.766 96 131.58 96 128.883Z" fill="#eee"/>
                                    <path id="Ellipse 1_68" d="M118.852 128.492C118.852 128.87 118.636 129.257 118.185 129.635C117.735 130.012 117.072 130.36 116.236 130.656C114.565 131.248 112.245 131.617 109.672 131.617C107.099 131.617 104.779 131.248 103.108 130.656C102.272 130.36 101.609 130.012 101.159 129.635C100.708 129.257 100.492 128.87 100.492 128.492C100.492 128.114 100.708 127.727 101.159 127.35C101.609 126.973 102.272 126.625 103.108 126.328C104.779 125.737 107.099 125.367 109.672 125.367C112.245 125.367 114.565 125.737 116.236 126.328C117.072 126.625 117.735 126.973 118.185 127.35C118.636 127.727 118.852 128.114 118.852 128.492Z" fill="#CDCBBF" stroke="#999999" strokeWidth="0.390625"/>
                                    <ellipse id="Ellipse 3_68" cx="109.672" cy="128.883" rx="9.375" ry="3.32031" fill="#fff"/>
                                    <g id="Subtract_68"  opacity="0.4">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M123.333 129.078C123.34 129.143 123.344 129.208 123.344 129.273C123.344 131.97 117.223 134.156 109.672 134.156C102.121 134.156 96 131.97 96 129.273C96 129.208 96.0036 129.143 96.0107 129.078C96.2978 131.684 102.304 133.766 109.672 133.766C117.039 133.766 123.046 131.684 123.333 129.078Z" fill="#ABABAB"/>
                                    </g>
                                </g>
                            </g>
                            <g className="coin-right" id="coin with bottom_65">
                                <path id="bottom_65" d="M96 125.273V128.789C96 131.486 102.121 133.672 109.672 133.672C117.223 133.672 123.344 131.486 123.344 128.789V125.315L123.343 125.315C123.28 127.993 117.183 130.156 109.672 130.156C102.121 130.156 96 127.97 96 125.273Z" fill="#999999"/>
                                <g>
                                    <ellipse id="Ellipse 2_69" cx="109.672" cy="124.883" rx="13.6719" ry="4.88281" fill="#eee"/>
                                    <path id="Exclude_69" d="M96 124.883V128.398C96 131.095 102.121 133.281 109.672 133.281C117.223 133.281 123.344 131.095 123.344 128.398V124.925L123.343 124.925C123.28 127.602 117.183 129.766 109.672 129.766C102.121 129.766 96 127.58 96 124.883Z" fill="#eee"/>
                                    <path id="Ellipse 1_69" d="M118.852 124.492C118.852 124.87 118.636 125.257 118.185 125.635C117.735 126.012 117.072 126.36 116.236 126.656C114.565 127.248 112.245 127.617 109.672 127.617C107.099 127.617 104.779 127.248 103.108 126.656C102.272 126.36 101.609 126.012 101.159 125.635C100.708 125.257 100.492 124.87 100.492 124.492C100.492 124.114 100.708 123.727 101.159 123.35C101.609 122.973 102.272 122.625 103.108 122.328C104.779 121.737 107.099 121.367 109.672 121.367C112.245 121.367 114.565 121.737 116.236 122.328C117.072 122.625 117.735 122.973 118.185 123.35C118.636 123.727 118.852 124.114 118.852 124.492Z" fill="#CDCBBF" stroke="#999999" strokeWidth="0.390625"/>
                                    <ellipse id="Ellipse 3_69" cx="109.672" cy="124.883" rx="9.375" ry="3.32031" fill="#fff"/>
                                    <g id="Subtract_69"  opacity="0.4">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M123.333 125.078C123.34 125.143 123.344 125.208 123.344 125.273C123.344 127.97 117.223 130.156 109.672 130.156C102.121 130.156 96 127.97 96 125.273C96 125.208 96.0036 125.143 96.0107 125.078C96.2978 127.684 102.304 129.766 109.672 129.766C117.039 129.766 123.046 127.684 123.333 125.078Z" fill="#ABABAB"/>
                                    </g>
                                </g>
                            </g>
                            <g className="coin-right" id="coin with bottom_66">
                                <path id="bottom_66" d="M96 121.273V124.789C96 127.486 102.121 129.672 109.672 129.672C117.223 129.672 123.344 127.486 123.344 124.789V121.315L123.343 121.315C123.28 123.993 117.183 126.156 109.672 126.156C102.121 126.156 96 123.97 96 121.273Z" fill="#999999"/>
                                <g>
                                    <ellipse id="Ellipse 2_70" cx="109.672" cy="120.883" rx="13.6719" ry="4.88281" fill="#eee"/>
                                    <path id="Exclude_70" d="M96 120.883V124.398C96 127.095 102.121 129.281 109.672 129.281C117.223 129.281 123.344 127.095 123.344 124.398V120.925L123.343 120.925C123.28 123.602 117.183 125.766 109.672 125.766C102.121 125.766 96 123.58 96 120.883Z" fill="#eee"/>
                                    <path id="Ellipse 1_70" d="M118.852 120.492C118.852 120.87 118.636 121.257 118.185 121.635C117.735 122.012 117.072 122.36 116.236 122.656C114.565 123.248 112.245 123.617 109.672 123.617C107.099 123.617 104.779 123.248 103.108 122.656C102.272 122.36 101.609 122.012 101.159 121.635C100.708 121.257 100.492 120.87 100.492 120.492C100.492 120.114 100.708 119.727 101.159 119.35C101.609 118.973 102.272 118.625 103.108 118.328C104.779 117.737 107.099 117.367 109.672 117.367C112.245 117.367 114.565 117.737 116.236 118.328C117.072 118.625 117.735 118.973 118.185 119.35C118.636 119.727 118.852 120.114 118.852 120.492Z" fill="#CDCBBF" stroke="#999999" strokeWidth="0.390625"/>
                                    <ellipse id="Ellipse 3_70" cx="109.672" cy="120.883" rx="9.375" ry="3.32031" fill="#fff"/>
                                    <g id="Subtract_70"  opacity="0.4">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M123.333 121.078C123.34 121.143 123.344 121.208 123.344 121.273C123.344 123.97 117.223 126.156 109.672 126.156C102.121 126.156 96 123.97 96 121.273C96 121.208 96.0036 121.143 96.0107 121.078C96.2978 123.684 102.304 125.766 109.672 125.766C117.039 125.766 123.046 123.684 123.333 121.078Z" fill="#ABABAB"/>
                                    </g>
                                </g>
                            </g>
                            <g className="coin-right" id="coin with bottom_67">
                                <path id="bottom_67" d="M96 117.273V120.789C96 123.486 102.121 125.672 109.672 125.672C117.223 125.672 123.344 123.486 123.344 120.789V117.315L123.343 117.315C123.28 119.993 117.183 122.156 109.672 122.156C102.121 122.156 96 119.97 96 117.273Z" fill="#999999"/>
                                <g>
                                    <ellipse id="Ellipse 2_71" cx="109.672" cy="116.883" rx="13.6719" ry="4.88281" fill="#eee"/>
                                    <path id="Exclude_71" d="M96 116.883V120.398C96 123.095 102.121 125.281 109.672 125.281C117.223 125.281 123.344 123.095 123.344 120.398V116.925L123.343 116.925C123.28 119.602 117.183 121.766 109.672 121.766C102.121 121.766 96 119.58 96 116.883Z" fill="#eee"/>
                                    <path id="Ellipse 1_71" d="M118.852 116.492C118.852 116.87 118.636 117.257 118.185 117.635C117.735 118.012 117.072 118.36 116.236 118.656C114.565 119.248 112.245 119.617 109.672 119.617C107.099 119.617 104.779 119.248 103.108 118.656C102.272 118.36 101.609 118.012 101.159 117.635C100.708 117.257 100.492 116.87 100.492 116.492C100.492 116.114 100.708 115.727 101.159 115.35C101.609 114.973 102.272 114.625 103.108 114.328C104.779 113.737 107.099 113.367 109.672 113.367C112.245 113.367 114.565 113.737 116.236 114.328C117.072 114.625 117.735 114.973 118.185 115.35C118.636 115.727 118.852 116.114 118.852 116.492Z" fill="#CDCBBF" stroke="#999999" strokeWidth="0.390625"/>
                                    <ellipse id="Ellipse 3_71" cx="109.672" cy="116.883" rx="9.375" ry="3.32031" fill="#fff"/>
                                    <g id="Subtract_71"  opacity="0.4">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M123.333 117.078C123.34 117.143 123.344 117.208 123.344 117.273C123.344 119.97 117.223 122.156 109.672 122.156C102.121 122.156 96 119.97 96 117.273C96 117.208 96.0036 117.143 96.0107 117.078C96.2978 119.684 102.304 121.766 109.672 121.766C117.039 121.766 123.046 119.684 123.333 117.078Z" fill="#ABABAB"/>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>

    )

}