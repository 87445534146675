import React from 'react'
import Check from "../icons/check"
import Cross from "../icons/cross"
import AspspPermissionMapping from "../../../mapping/aspsp-permissions.json"

enum IAvailability {
    available,
    unavailable
}

interface IPermission {
    label: string
    readonly: boolean
    availability: IAvailability
}

interface IAspsp {
    id: string,
    label: string,
    permissions: Array<string>
}

interface IProps {
    aspsp: IAspsp
}

const generateColumn = (permissions: Array<IPermission>) => {
    return (
        <div className="inline-block">
            <ul>
                {
                    permissions.map((p: IPermission) => {
                        return <li>
                            <p className="ml-2 text-base inline-block truncate"> <span className="mr-2">{p.availability === IAvailability.available ?
                                <Check className="inline-block"/> :
                                <Cross className="inline-block" color="#AE1F00"/>}</span>
                            {p.label}</p>
                        </li>
                    })
                }
            </ul>
        </div>
    )
}

const AspspPermissions = ({...props}: IProps) => {

    const aspsp = props.aspsp

    const readOnlyPermissions = Object.keys(AspspPermissionMapping.aspspPermissions)
        .filter(pk => {
            return AspspPermissionMapping.aspspPermissions[pk].readonly && aspsp.permissions.some(p => p === pk);
        })

    const readOnlyNotAvailablePermissions = Object.keys(AspspPermissionMapping.aspspPermissions)
        .filter(pk => AspspPermissionMapping.aspspPermissions[pk].readonly && !readOnlyPermissions.some(ropk => AspspPermissionMapping.aspspPermissions[ropk].label === AspspPermissionMapping.aspspPermissions[pk].label))

    const readWritePermissions = Object.keys(AspspPermissionMapping.aspspPermissions)
        .filter(pk => {
            return !AspspPermissionMapping.aspspPermissions[pk].readonly && aspsp.permissions.some(p => p === pk);
        })

    const readWriteNotAvailablePermissions = Object.keys(AspspPermissionMapping.aspspPermissions)
        .filter(pk => !AspspPermissionMapping.aspspPermissions[pk].readonly && !readWritePermissions.some(ropk => AspspPermissionMapping.aspspPermissions[ropk].label === AspspPermissionMapping.aspspPermissions[pk].label))

    return (
        <>
            <div className="hidden md:block mt-8 mb-4">
                <div className="inline-block w-1/2">
                    <p className="text-primary">View</p>
                </div>
                <div className="inline-block w-1/2">
                    <p className="text-primary">Do</p>
                </div>
            </div>
            <div className="my-4 md:hidden">
                <p className="text-primary">View</p>
            </div>
            <div className="mb-4 md:mb-0 md:inline-block md:w-1/4">
                {generateColumn(
                    readOnlyPermissions
                        .filter(uniquePermissionMappings)
                        .sort((a, b) => AspspPermissionMapping.aspspPermissions[a].label > AspspPermissionMapping.aspspPermissions[b].label ? 1 : -1)
                        .map(pk => ({
                            label: AspspPermissionMapping.aspspPermissions[pk].label,
                            readonly: true,
                            availability: IAvailability.available
                        }))
                )}
            </div>
            <div className="mb-4 md:mb-0 md:inline-block md:w-1/4">
                {generateColumn(
                    readOnlyNotAvailablePermissions
                        .filter(uniquePermissionMappings)
                        .sort((a, b) => AspspPermissionMapping.aspspPermissions[a].label > AspspPermissionMapping.aspspPermissions[b].label ? 1 : -1)
                        .map(pk => ({
                            label: AspspPermissionMapping.aspspPermissions[pk].label,
                            readonly: true,
                            availability: IAvailability.unavailable
                        })))}
            </div>
            <div className="my-4 md:hidden">
                <p className="text-primary">Do</p>
            </div>
            <div className="mb-4 md:mb-0 md:inline-block md:w-1/4">
                {generateColumn(
                    readWritePermissions
                        .filter(uniquePermissionMappings)
                        .sort((a, b) => AspspPermissionMapping.aspspPermissions[a].label > AspspPermissionMapping.aspspPermissions[b].label ? 1 : -1)
                        .map(pk => ({
                            label: AspspPermissionMapping.aspspPermissions[pk].label,
                            readonly: false,
                            availability: IAvailability.available
                        })))}
            </div>
            <div className="mb-4 md:mb-0 md:inline-block md:w-1/4">
                {generateColumn(
                    readWriteNotAvailablePermissions
                        .filter(uniquePermissionMappings)
                        .sort((a, b) => AspspPermissionMapping.aspspPermissions[a].label > AspspPermissionMapping.aspspPermissions[b].label ? 1 : -1)
                        .map(pk => ({
                            label: AspspPermissionMapping.aspspPermissions[pk].label,
                            readonly: false,
                            availability: IAvailability.unavailable
                        })))}
            </div>
        </>
    )


}

const uniquePermissionMappings = (pk: string, i: number, self: Array<string>) => self.findIndex(p => (AspspPermissionMapping.aspspPermissions[p].label === AspspPermissionMapping.aspspPermissions[pk].label)) == i

export default AspspPermissions