import * as React from "react"

import Layout from "../components/sitewide/layout"
import Seo from "../components/sitewide/seo"
import {TickBullet} from "../components/icons/tick-bullet"
import PhoneInCircle from "../components/images/phone-in-circle"
import Finances from "../components/images/your-finances"
import HomeLineHorizontal from "../components/images/home-line-horizontal"
import {useState} from "react"
import FeatureDirect from "../components/images/feature-direct"
import FeatureSecure from "../components/images/feature-secure"
import FeatureFast from "../components/images/feature-fast"
import { Icon } from '@iconify/react'
import roundAutoAwesome from '@iconify-icons/ic/round-auto-awesome'
import '../components/home/home.css'
import Carousel from "../components/misc/carousel"
import CarouselItem1 from "../components/home/carousel-item-1"
import CarouselItem2 from "../components/home/carousel-item-2"
import CarouselItem3 from "../components/home/carousel-item-3"
import CarouselItem4 from "../components/home/carousel-item-4"
import AspspPermissions from "../components/home/aspsp-permissions"
import apiOperations from "../../data/api-operations.json"
import Select from "react-dropdown-select"
import Cross from "../components/icons/cross";
import HomeLineVertical from "../components/images/home-line-vertical";
import scrollTo from 'gatsby-plugin-smoothscroll'

export default function IndexPage() {

    // const [ddState, updateDdState] = useState(0)
    // const ddOptions = [
    //     {id: 'example-1', label: <div><span className="text-primary">Example 1:&nbsp;</span><span>Track your carbon footprint.</span></div>},
    //     {id: 'example-2', label: <div><span className="text-primary">Example 2:&nbsp;</span><span>Saving and investing £200 every month.</span></div>}
    // ]

    const [showSubscribe, setShowSubscribe] = useState(false)

    const aspsps = apiOperations.map((ao, i) => {
        return {
            ix: i,
            id: ao.name.toLowerCase().replace(" ", "-").trim(),
            label: ao.name,
            permissions: Object.keys(ao.operations).flatMap(op => Object.keys(ao.operations[op]).filter(ok => ao.operations[op][ok]).map(ok => ok.toUpperCase() + "." + op))
        }
    })
    const [selectedAspspIx, updateSelectedAspspIx] = useState(-1)
    const [selectedAspsp, updateSelectedAspsp] = useState(aspsps[0])
    // const aspsps = [
    //     {id: 'barclays', label: <div><span>Barclays</span></div>},
    // ]

    return (
        <Layout>
            <Seo title="Home"/>
            <section
                className="mt-15vh mb-15vh motion-safe:animate-fade-in-1-5s">
                <div className="md:flex md:flex-wrap">
                    <div
                        className="mb-10">
                        <h1 className="mb-4 text-primary">Open banking — your money as data.</h1>
                        {/*<p className="mb-10">In the UK, apps and websites authorised by <a className="text-primary" href="#">the FCA</a> help you to understand and control your finances with open banking.</p>*/}
                        <p className="mb-10">Open banking allows regulated companies to help you understand and control your finances. Get in touch to find out more.</p>
                        <p className="mb-6">Some reasons for open banking:</p>
                        <ul>
                            <li>

                                <p className="inline-block"><span
                                    className="inline-block mr-4"><TickBullet/></span>track spending on
                                    things you care about</p>
                            </li>
                            <li>
                                <p className="inline-block"><span
                                    className="inline-block mr-4"><TickBullet/></span>achieve better
                                    access to credit</p>
                            </li>
                            <li>
                                <p className="inline-block"><span
                                    className="inline-block mr-4"><TickBullet/></span>save more money</p>
                            </li>
                        </ul>
                    </div>
                    <div
                        className="m-auto grid grid-flow-row md:grid-flow-col justify-between text-center grid-cols-1 align-middle">
                        <div className="order-1 md:order-1 m-auto">
                            <Finances/>
                            <p className="mt-6">Your finances</p>
                        </div>
                        <div className="hidden md:block order-2 md:order-2 mt-auto mb-auto ml-10 mr-10 pt-24">
                            <HomeLineHorizontal/>
                            <p className="mt-6">Open banking</p>
                        </div>
                        <div className="order-2 mt-10 md:hidden m-auto">
                            <HomeLineVertical/>
                        </div>
                        <div className="order-3 md:order-3 m-auto">
                            <PhoneInCircle/>
                            <p className="mt-6">Authorised app</p>
                        </div>
                    </div>
                </div>
            </section>
            <section
                className="mt-15vh mb-15vh motion-safe:animate-fade-in-1-5s">
                <div className="">
                    <div
                        className="">
                        <h1 className="mb-4 text-primary">You control access to
                            your payment account.</h1>
                    </div>
                    {/*<p>See how it works below. If you'd like to understand it further, check out our <a className="text-primary" href="#">technical explainers</a>.</p>*/}
                    <p>See how it works below. If you'd like to understand it further, <a className="cursor-pointer text-primary" onClick={() => scrollTo('#subscribe')}>subscribe</a> to hear about our upcoming technical explainers.</p>
                    <Carousel className="m-auto" showDots={true} autoplay={true} components={[
                        (<CarouselItem1 className="m-auto text-base"/>),
                        (<CarouselItem2 className="m-auto text-base"/>),
                        (<CarouselItem3 className="m-auto text-base"/>),
                        (<CarouselItem4 className="m-auto text-base"/>),
                    ]} />
                    {/*<div className="w-6/12">*/}
                    {/*    <Dropdown options={ddOptions} selected={ddState} updateSelected={updateDdState}/>*/}
                    {/*</div>*/}
                </div>
            </section>
            <section
                className="mt-15vh mb-15vh motion-safe:animate-fade-in-1-5s">
                <div className="">
                    <div
                        className="">
                        <h1 id="providers" className="mb-4 text-primary">See what your account provider allows.</h1>
                    </div>
                    <div className="md:w-5/12">
                        <Select
                            className="bg-cta-primary rounded-md text-cta-primary hover:bg-cta-primary-h px-4 py-2"
                            values={[]}
                            options={aspsps}
                            onChange={(value) => {
                                if(value.length > 0 )updateSelectedAspspIx(value[0].ix)
                            }}/>
                        {/*<Dropdown isPrimary={true} options={aspsps} selected={selectedAspsp} updateSelected={updateSelectedAspsp}/>*/}
                    </div>
                    {selectedAspspIx > -1 ? <AspspPermissions aspsp={aspsps[selectedAspspIx]} /> : <></>}
                </div>
            </section>
            <section
                className="mt-15vh mb-15vh motion-safe:animate-fade-in-1-5s">
                <div className="">
                    <div
                        className="">
                        <h1 className="mb-4 text-primary">We’re making finance more transparent.</h1>
                    </div>
                    <p className="mb-10">
                        We work with <a className="text-primary" href="mailto:hello@platern.com">developers and businesses</a> to build direct connections to payment account providers.
                    </p>
                    <div className="md:flex md:flex-wrap w-full justify-around text-center">
                        <div className="mb-8">
                            <FeatureDirect className="m-auto"/>
                            <p className="mt-8">No hidden parties</p>
                        </div>
                        <div className="mb-8">
                            <FeatureSecure className="m-auto"/>
                            <p className="mt-8">Highly secure</p>
                        </div>
                        <div className="mb-8">
                            <FeatureFast className="m-auto"/>
                            <p className="mt-8">Fast connections</p>
                        </div>
                    </div>
                </div>
            </section>
            <section id="subscribe" className="mt-15vh mb-15vh motion-safe:animate-fade-in-1-5s">
                <div
                    className="m-auto text-center">
                    <h1 className="mb-4 text-primary">Want to stay informed?&nbsp;<Icon className="inline-block hover:text-accent" icon={roundAutoAwesome} /></h1>
                    <p>Join our global community to receive occasional tips and updates.</p>
                    { !showSubscribe ? <button className="mt-8 bg-cta-primary text-cta-primary px-12 py-2 rounded" onClick={() => setShowSubscribe(true)}>Subscribe</button> : <></> }
                    { showSubscribe ? <div style={{width: 540}} className="m-auto">
                        <div onClick={() => setShowSubscribe(false)}><Cross className="my-4 cursor-pointer fill-primary relative right-0 top-0" style={{transform: "scale(2)"}}/></div>
                        <iframe width="540" height="605"
                                src="https://fdf4654c.sibforms.com/serve/MUIEADaSaAZkdRXgXQhiyiZedjm-pOFVf1yly-B3lKa7YrZGqZT3X8XusV0AltSAg6x5UY5B0BB8ZejzrwY_3XJvFnJN5WZgCR-RsWvU0YK8xGTaLSF_XtQRucNJryF0Ct3P38RL7NbZxp028ZjK5V4efULVIS8YuWEJAP9xvJQuTACq9UwISE5decnl_uydd9xsEjUuWV_rXaaX"
                                frameBorder="0" scrolling="auto" allowFullScreen
                                style={{
                                    display: 'block',
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    maxWidth: '100%'
                                }} />
                    </div> : <></>}
                </div>
            </section>
        </Layout>
    )
}
