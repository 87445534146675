import React from 'react'

interface IProps {
    className?: string
    style?: any
    color?: string
}

const Cross = ({...props}: IProps) => {

    return (
        <svg className={props.className} style={props.style} width="9" height="10" fill={props.color ? props.color : "none"}
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.218 1.086a1.5 1.5 0 010 2.121L6.425 5l1.793 1.793a1.5 1.5 0 11-2.122 2.121L4.304 7.122 2.562 8.864A1.5 1.5 0 11.44 6.743L2.182 5 .44 3.257a1.5 1.5 0 112.122-2.12l1.743 1.742 1.793-1.793a1.5 1.5 0 012.121 0z"
            />
        </svg>
    )

}

export default Cross