import React from 'react'
import CarouselImage1 from "./carousel-image-1";
import {IProps} from "./carousel-item";
import CarouselImageMob1 from "./carousel-image-mob1";

const CarouselItem1 = ({...props}: IProps) => {
    return (
        <div className={`${props.className}`}>
            <div className="md:hidden"><CarouselImageMob1 /></div>
            <div className="hidden md:block"><CarouselImage1 /></div>
            <p className="mt-10 text-center"><a className="">Authorised apps (or
                websites)</a> must ask your permission to access your payment
                account.</p>
        </div>
    )
}

export default CarouselItem1